import { initializeApp } from "firebase/app";


import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCICx9NLOuDeFUUZ1aU9Roh1QQ-DkYoVFw",
  authDomain: "gym-manager-app.firebaseapp.com",
  databaseURL: "https://gym-manager-app.firebaseio.com",
  projectId: "gym-manager-app",
  storageBucket: "gym-manager-app.appspot.com",
  messagingSenderId: "442791493546",
  appId: "1:442791493546:web:ffd2d4b1797602ca1d35d3",
  measurementId: "G-0H5YZ4P8BC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    await insertNewUserInUsers(res.user, "google")

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    await insertNewUserInUsers(res.user, "email")
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const insertNewUserInUsers = async (user, authProvider) => {

  try {
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider,
        email: user.email,
      });
    }
  } catch (error) {
    console.log(error)
  }

}

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  window.setAppState((prevAppState) => ({ ...prevAppState, UserData: undefined }))
  signOut(auth);
};
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};