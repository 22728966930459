import React, { useEffect, useState, useContext, useMemo } from "react";
import { ListItem, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/el";
import { AppContext } from "../../App";
import { Payment } from "./ClientInfo";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { dateDiffString, getClientFullName } from "../../data/db";
import Client from "./Client";

export default function Payments() {
  const appState = useContext(AppContext);
  const navigate = useNavigate();

  const group = appState.payments
    ? appState.payments
        .sort((a, b) => b.date - a.date)
        .reduce((r, a) => {
          r[moment(a.date.toDate()).format("MMMM yyyy")] = [...(r[moment(a.date.toDate()).format("MMMM yyyy")] || []), { ...a, clientFullName: getClientFullName(a.client) }];
          return r;
        }, [])
    : [];

  return (
    <div style={{ position: "absolute", verflow: "auto", top: "2vh", left: "10px", right: "10px", bottom: "1vh", overflow: "auto" }}>
      {appState.payments && appState.payments.length > 0 && (
        <>
          {Object.keys(group).map((element, index) => (
            <Accordion key={index} defaultExpanded={index === 0 ? true : false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <div style={{ flex: "100", fontWeight: "450" }}>{element}</div>

                  <div style={{ textAlign: "center", fontSize: ".8rem", flex: "25", color: "#999" }}>{group[element].length}</div>
                  <div style={{ flex: "20", textAlign: "end", color: "green", fontWeight: "700" }}>€{group[element].reduce((partialSum, a) => partialSum + a.amount, 0)}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ paddingInline: "10px", paddingTop: "0px", margin: "0" }}>
                {group[element].map((p, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      window.showModal({
                        Body: <Payment payment={p} />,
                        Title: "Πληρωμή από " + p.clientFullName
                      });
                    }}
                    style={{
                      width: "100%",
                      paddingTop: "5px",
                      paddingBottom: "2px",
                      backgroundColor: "#f5f5f5",
                      paddingLeft: "5px",
                      paddingRight: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>
                        <div style={{ color: "#444", flexGrow: "1", textAlign: "start", fontSize: "0.95rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {p.clientFullName}
                        </div>
                        <div style={{ color: "#777", flexShrink: "1", fontSize: "0.7rem", paddingRight: "7px" }}>
                          {dateDiffString(p.date.toDate())} ({moment(p.date.toDate()).format("ddd DD/MM/yyyy")})
                        </div>
                      </div>

                      <div style={{ flex: "2", fontWeight: "700", textAlign: "end", color: "green", fontSize: ".9rem" }}>€{p.amount}</div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        {p.idate && <div style={{ fontSize: "12px", textAlign: "end", color: "green" }}>{`#${p.inum} - ${moment(p.idate?.toDate()).format("DD/MM/yyyy")} - €${p.iamount}`}</div>}
                      </div>
                      <div style={{ textAlign: "end", paddingBottom: "4px", color: "#999", fontSize: "0.7rem" }}>{appState.nameReplace(p.user)}</div>
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
}
