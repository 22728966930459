import "./forms.css";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Paper } from '@mui/material';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { AppContext } from "../../App";
import { fetchDoc, saveDoc, delDoc, getPlanExtra, getPlanFullName, getClientFullName,getSubscriptionNextCharge } from '../../data/db'
import { TextInput, SelectInput, FormRow, CheckBox } from "../controls/Inputs"
import moment from "moment";
import "moment/locale/el";

import { SavedSearchOutlined, UnfoldLess } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Subscription(props) {

    const appState = useContext(AppContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [doc, setDoc] = useState(null)

    useEffect(() => {
        if (appState.dbRoot === undefined) navigate('/dashboard/1')
    }, [])


    useEffect(() => {
        if (id && appState.dbRoot !== undefined) {
            if (id.toString().startsWith('client:')) {
                setDoc({ client: id.split(':')[1] })
            }
            else {
                fetchDoc(id, 'subscriptions').then(setDoc)
            }

        }
    }, [id])


    return (

        <Box style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", backgroundColor: "#ffffea", overflow: "auto" }}
        >
            <div style={{ flex: "1", minHeight: "60px" }} >
                <AppBar position="fixed">
                    <Toolbar style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                        <IconButton
                            color="inherit"
                            onClick={() => { navigate(-1) }}
                            edge="start">
                            <BackIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" style={{ flex: 50, userSelect: "none" }} >
                            {doc ? 'Συνδρομή' : 'Νεά συνδρομή'}

                        </Typography>

                        {/* {id !== undefined &&
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    window.setAppState((appState) => (
                                        {
                                            ...appState,
                                            DialogData:
                                            {
                                                Message: "Να πραγματοποιηθεί η διαγραφή του συνδρομής;",
                                                onAgree: () => {

                                                    delDoc(id, 'subscriptions', doc, appState.user)
                                                        .then(() => navigate(-1))
                                                        .catch((error) => {
                                                            window.showAlert(`${error}`)
                                                        });
                                                }
                                            }
                                        }))

                                }}
                                style={{ paddingInline: "7px" }}

                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                if (!doc || doc.from === undefined) {
                                    
                                    
                                    window.showAlert('Η έναρξη είναι υποχρεωτική!')
                                }
                                else {
                                    window.showDialog(
                                        {
                                            Message: "Να πραγματοποιηθεί η αποθήκευση του συνδρομής;",
                                            ButtonExtraName: "Ναι & Επιστροφη",
                                            onAgree: () => {

                                                saveDoc(id, 'Subscriptions', doc, appState.user).then((refId) => {
                                                    if (!id) navigate(`/subscription/${refId.id}`, { replace: true });
                                                }).catch((error) => {
                                                    window.showAlert(`${error}`)
                                                })
                                            },
                                            onExtraButton: () => {
                                                saveDoc(id, 'Subscriptions', doc, appState.user).then((refId) => {
                                                    navigate(-1);
                                                }).catch((error) => {
                                                    window.showAlert(`${error}`)
                                                })
                                            }
                                        }
                                    )
                                }
                            }}
                            style={{ paddingInline: "7px" }}
                        >
                            <SaveIcon />
                        </IconButton> */}
                    </Toolbar>
                </AppBar>
            </div>
            <Box style={{ padding: "0px 10px 10px 10px", marginTop: "7px", display: 'flex', flexDirection: "column", height: "100%", overflow: "auto" }}>

                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <SelectInput disabled flex="4" field="client" label="Πελάτης" object={doc} setObject={setDoc}
                        options={window.appState.clients.map(c => ({ id: c.id, name: getClientFullName(c) }))} />
                </FormRow>

                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <SelectInput flex="4" field="plan" label="Πακέτο" object={doc} setObject={setDoc}
                        options={window.appState.plans.map(p=>({id:p.id,name:getPlanFullName(p,true)}))} />
                </FormRow>



                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput type="date" field="from" label="Έναρξη" object={doc} setObject={setDoc} />
                    <TextInput type="date" field="to" label="Λήξη" object={doc} setObject={setDoc} />
                </FormRow>


                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="3" field="price" label="Ποσό Συνδρομής" object={doc} setObject={setDoc} type="€" />
                    <TextInput flex="3" field="reduction" label="Εφάπαξ Έκπτωση" object={doc} setObject={setDoc} type="€" />

                </FormRow>
                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="3" field="extraDays" label="Ελεύθερες Μέρες" object={doc} setObject={setDoc} type="number" />
                    <TextInput flex="3" field="nextCharge" label="Ανανέωση" value={doc ? moment(getSubscriptionNextCharge(doc)).format("DD/MM/yyyy"):''} disabled/>
                </FormRow>


                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="1" field="notes" label="Παρατηρήσεις" object={doc} setObject={setDoc} rows={10} />
                </FormRow>


            </Box>

        </Box >
    )

}