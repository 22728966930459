import React, { useEffect, useState, useContext, useMemo } from "react";
import { ListItem, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/el";
import { AppContext } from "../../App";
import { getClientFullName } from "../../data/db";

import { db } from "../../data/firebase";
import { query, collection, onSnapshot, where, orderBy, getDocs } from "firebase/firestore";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import FitnessCenter from "@mui/icons-material/FitnessCenter";
import ListIcon from "@mui/icons-material/List";

import PropagateLoader from "react-spinners/PropagateLoader";
import { Payment } from "./ClientInfo";

export default function Logs() {
  const appState = useContext(AppContext);

  const [group, setGroup] = useState();

  useEffect(() => {
    if (!appState.logs) {
      const date = new Date();
      const startOfDay = new Date(date.getFullYear(), date.getMonth(), 1);

      const q = query(collection(db, appState.dbRoot + "History"), where("timestamp", ">=", startOfDay), orderBy("timestamp", "desc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        window.setAppState((prevState) => {
          return {
            ...prevState,
            logs: querySnapshot.docs.map((d) => {
              return { ...d.data(), id: d.id, docRef: d.ref };
            }),
          };
        });
      });
    }
  }, [group]);

  const loadOldLogs = (month_year, index) => {
    if (index !== 0 && (!appState.oldLogs || appState.oldLogs[month_year] === undefined)) {
      let startD = new Date(Number(month_year.split(" ")[1]), Number(month_year.split(" ")[0]) - 1, 1);
      let endD = new Date(Number(month_year.split(" ")[1]), Number(month_year.split(" ")[0]), 1);

      console.log(month_year, moment(startD).format("DD/MM/yyyy") + " " + moment(endD).format("DD/MM/yyyy"));
      const q = query(collection(db, appState.dbRoot + "History"), where("timestamp", ">=", startD), where("timestamp", "<", endD), orderBy("timestamp", "desc"));

      getDocs(q).then((querySnapshot) => {
        const list = querySnapshot.docs.map((d) => {
          return { ...d.data(), id: d.id, docRef: d.ref };
        });
        const monthLogs = createMonthLogs(list, appState);

        window.setAppState((prevState) => {
          let tmp = { ...prevState };
          if (!tmp.oldLogs) {
            tmp.oldLogs = {};
          }
          tmp.oldLogs[month_year] = monthLogs;
          return tmp;
        });
      });
    }
  };

  useMemo(() => {
    if (appState.clients && appState.logs && !group) {
      let tmp = {};

      const today = new Date();
      let tmpDate = new Date(today.getFullYear(), today.getMonth(), 1);

      const monthLogs = createMonthLogs(appState.logs, appState);

      tmp[moment(tmpDate).format("M yyyy")] = monthLogs;

      while (tmpDate > new Date(2020, 1, 1)) {
        tmpDate = moment(tmpDate).add(-1, "M");
        tmp[moment(tmpDate).format("M yyyy")] = [];
      }

      setGroup(tmp);
    }
  }, [appState.logs, appState.clients]);

  return (
    <div style={{ userSelect: "none", position: "absolute", verflow: "auto", top: "2vh", left: "10px", right: "10px", bottom: "1vh", overflow: "auto" }}>
      {appState.clients && group !== undefined && (
        <>
          {Object.keys(group).map((element, index) => (
            <Accordion key={index} onClick={() => loadOldLogs(element, index)} defaultExpanded={index === 0 ? true : false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <div style={{ flex: "10", fontWeight: "450" }}>
                    {moment().subtract(index, "month").startOf("month").format("MMMM")} {element.split(" ")[1]}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ paddingInline: "10px", paddingTop: "0px", margin: "0" }}>
                {index === 0 && (
                  <>
                    {group[element].map((p, index) => (
                      <LogsItem key={index} logItem={p} />
                    ))}
                  </>
                )}
                {index !== 0 && (
                  <>
                    {!appState.oldLogs?.[element] && (
                      <div style={{ display: "flex", alignContent: "center", justifyContent: "center", minHeight: "30px" }}>
                        <PropagateLoader color="#aaa" size={12} />
                      </div>
                    )}

                    {appState.oldLogs?.[element] && Array.isArray(appState.oldLogs[element]) && appState.oldLogs[element].map((p, index) => <LogsItem key={`element-${index}`} logItem={p} />)}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
}

export function LogsItem(props) {
  const p = props.logItem;
  console.log(props);

  const appState = useContext(AppContext);

  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "5px",
          backgroundColor: "#f5f5f5",
          marginBottom: "7px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            width: "100%",

            alignItems: "flex-start",
          }}
        >
          <div style={{ marginTop: "5px", color: p.color, fontSize: "0.85rem", lineHeight: "0.4rem" }}>{p.typeText}</div>
          <div>
            <div style={{ fontSize: "0.7rem" }}>{p.date} </div>
            <div style={{ textAlign: "end", color: "#777", fontSize: "0.7rem", lineHeight: "0.8rem" }}>{appState.nameReplace(p.user)}</div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: "1", textAlign: "start", fontSize: "0.8rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <>
              {p.clients &&
                p.clients.map((c, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (!c.isClientDeleted && c.clientLink !== "") navigate(c.clientLink);
                    }}
                  >
                    <span style={{ cursor: c.isClientDeleted ? "" : "pointer", color: "#008" }}>{c.clientFullName}</span>
                  </div>
                ))}
              <div
                style={{ marginTop: "5px", textAlign: "start", fontSize: "0.8rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                dangerouslySetInnerHTML={{ __html: p.extra.replaceAll("\n", "<br/>").replaceAll(" 00:00:00.000", "") }}
              />
            </>
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}
            onClick={() => {
              if (p.object === "Payment") {
                let pmt = window.appState.payments.filter((i) => i.id === p.documentId)[0];
                window.showModal({
                  Body: pmt ?<Payment payment={pmt}/> : <div style={{padding:"8px"}}>Η πληρωμή έχει διαγραφεί</div>,
                  Title: "Πληρωμή από " + p.clients[0].clientFullName,

                  Type: pmt ? undefined:"okOnly",
                });
              }
            }}
          >
            <span style={{ flexShrink: "1", alignSelf: "end", opacity: "0.8", color: p.color }}>{p.objetIcon}</span>
          </div>
        </div>
      </div>
    </>
  );
}

const createMonthLogs = (logs, appState) => {
  let tmpLogs = undefined;
  if (logs) {
    tmpLogs = [];

    console.log(logs);

    logs.forEach((p) => {
      let isClientDeleted = false;
      let client;
      let clientFullName;
      let objetIcon;
      try {
        if (appState.clients.filter((c) => c.id == p.client).length === 0) {
          if (p.type !== 3) clientFullName = `Πελάτης: ${clientFullName} (διαγραμμένος)`;
        } else {
          client = appState.clients.filter((c) => c.id === p.client)?.[0];
          clientFullName = getClientFullName(client);
        }
      } catch (error) {}

      if (!client && p.object !== "Plan") {
        clientFullName = "Διαγραμμένος πελάτης";
        isClientDeleted = true;
      }

      let typeText = "";
      if (p.object === "Client") {
        objetIcon = <PersonIcon style={{ fontSize: "1rem" }} />;
        if (p.type == 1) typeText = "Νέος πελάτης";
        else if (p.type == 2) typeText = "Ενημέρωση πελάτη";
        else if (p.type == 3) {
          typeText = "Διαγραφή πελάτη";
          isClientDeleted = true;
        }
      } else if (p.object === "Payment") {
        objetIcon = <PaymentIcon style={{ fontSize: "1rem" }} />;
        if (p.type == 1) typeText = "Νέα πληρωμή";
        else if (p.type == 2) typeText = "Ενημέρωση πληρωμής";
        else if (p.type == 3) typeText = "Διαγραφή πληρωμής";
      } else if (p.object === "Subscription") {
        objetIcon = <ListIcon style={{ fontSize: "1rem" }} />;
        if (p.type == 1) typeText = "Νέα συνδρομή";
        else if (p.type == 2) typeText = "Ενημέρωση συνδρομής";
        else if (p.type == 3) typeText = "Διαγραφή συνδρομής";
      } else if (p.object === "Plan") {
        objetIcon = <FitnessCenter style={{ fontSize: "1rem" }} />;
        if (p.type == 1) typeText = "Νέα πακέτο";
        else if (p.type == 2) typeText = "Ενημέρωση πακέτου";
        else if (p.type == 3) typeText = "Διαγραφή πακέτου";
      }

      let extra = p.text.toString().replace("\n", "<BR/>");

      var clientLink = "";
      if (p.client) clientLink = `/clientInfo/${p.client}`;

      const color = p.type == 2 ? "blue" : p.type == 1 ? "brown" : "#f60";

      const newLog = {
        color,
        date: moment(p.timestamp.toDate()).format("DD/MM/yyyy HH:mm:ss"),
        user: p.user,
        typeText,
        objetIcon,
        documentId: p.documentId,
        object: p.object,
        extra,
        clients: [{ clientFullName, clientLink, isClientDeleted }],
      };
      console.log(newLog);
      tmpLogs.push(newLog);
    });
  }
  return tmpLogs;
};
