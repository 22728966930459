import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../data/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard/0");
  }, [user, loading]);

  return (
    <div className="login">
      <div className="login__container">



        {showSpinner === true && <CircularProgress style={{ color: "white" }} />}
        {!showSpinner &&
          <>

            <div>
              <img src={process.env.PUBLIC_URL + "/images/main.png"} style={{ objectFit: "scale-down", maxWidth: "200px" }} />
            </div>
            <div style={{ minHeight: "50px" }}></div>
            <span style={{ opacity: "0.6", fontSize: "14px" }} >{window.version}</span>
            <div style={{ minHeight: "50px" }}></div>
            <input
              type="text"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
            <input
              type="password"
              className="login__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <button
              className="login__btn"
              onClick={() => {
                setShowSpinner(true);
                logInWithEmailAndPassword(email, password).then(() => setShowSpinner(false));

              }}
            >
              Σύνδεση
            </button>
            <button className="login__btn login__google"
              onClick={() => {
                setShowSpinner(true);
                signInWithGoogle().then(() => setShowSpinner(false));

              }}>

              Συνδέσου με Google
            </button>

            <div>
              <Link to="/reset">Ξέχασες το password</Link>
            </div>
            <div>
            Δεν έχεις λογαριασμό; <Link to="/register">Δημιούργησε</Link> ένα τώρα.
            </div>
          </>
        }
      </div>

    </div >
  );
}

export default Login;
