import { TextField, InputAdornment, Paper, Select, Checkbox, MenuItem, FormControl, InputLabel } from "@mui/material";

import uuid from "react-uuid";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import moment from "moment";
import "moment/locale/el";

import PhoneIcon from "@mui/icons-material/Phone";
import { Email } from "@mui/icons-material";

import { Timestamp } from "firebase/firestore";
import { MdOutlineClear, MdRemoveCircleOutline } from "react-icons/md";

export const Item = styled(Paper)(({ theme }) => ({
  padding: "0px  10px  5px  10px",

  color: theme.palette.text.secondary,
  userSelect: "none",
  lineHeight: "22px",
  minHeight: "15px",
  backgroundColor: "#ffffe8",
}));

export function TextInput(props) {
  const variant = "filled";
  const multiline = props.rows && props.rows > 1 ? true : false;

  const [id] = useState(uuid());

  return (
    <FormControl {...props} variant={variant} size="small" fullWidth>
      <div style={{ flex: props.flex, display: "flex", flexDirection: "column", margin: props.margin ? props.margin : "5px 5px 15px 5px" }}>
        <TextField
          disabled={props.disabled ? props.disabled : false}
          multiline={multiline}
          minRows={props.rows}
          label={props.label}
          type={props.type === "kg" || props.type === "cm" || props.type === "€" ? "number" : props.type}
          id={id}
          
          size="small"
          variant={variant}
          value={
            props.object
              ? props.type === "date" && props.object[props.field]
                ? props.object[props.field] 
                  ? moment(props.object[props.field].toDate()).format("yyyy-MM-DD")
                  : ""
                : props.object[props.field]
              : props.value ?? ""
          }
          //defaultValue={props.object ? (props.type !== 'date' ? undefined : moment(props.object[props.field]?.toDate()).format('yyyy-MM-DD')) : undefined}
          //defaultValue={'2020-01-01'}
          //value={props.object ? (props.type === 'date' ? '2021-01-01' : props.object[props.field]) : ''}

          onChange={(event) => {
            props.setObject((prevObject) => {
              var tmp = { ...prevObject };

              if (props.type === "number") {
                tmp[props.field] = event.target.value?.trim() === "" ? null : parseInt(event.target.value);
              } else if (props.type === "date") {
                tmp[props.field] = event.target.value !== "" ? Timestamp.fromDate(moment(event.target.value).toDate()) : null;
              } else {
                tmp[props.field] = event.target.value;
              }

              return tmp;
            });
          }}
          InputLabelProps={{ shrink: true, color: "success" }}
          inputProps={{ spellCheck: true }}
          InputProps={
            props.type === "kg" || props.type === "cm" || props.type === "€"
              ? {
                  endAdornment: (
                    <InputAdornment style={{ fontWeight: "600" }} position="start">
                      <span>{props.type}</span>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
        {/* {props.type === "date" && (
          <div
            onClick={() => {
              props.setObject((prevObject) => {
                var tmp = { ...prevObject };
                tmp[props.field] = null;
                return tmp;
              });
            }}
            style={{ position: "absolute", right: "45px", top: "30px" }}
          >
            <MdOutlineClear />
          </div>
        )} */}
      </div>
    </FormControl>
  );
}

export function Label(props) {
  const [id] = useState(uuid());
  return (
    <Item elevation={5} style={{ flex: props.flex, display: "flex", flexDirection: "column", margin: "3px 3px 5px 3px", padding: "3px 10px 8px 10px" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ flex: "100", display: "flex", flexDirection: "column" }}
          onClick={() => {
            if (props.object && props.object[props.field]) {
              navigator.clipboard.writeText(props.object[props.field]).then(
                () => {
                  console.log("clipboard successfully set");
                },
                (error) => {
                  console.log("clipboard write failed");
                }
              );
            }
          }}
        >
          <span style={{ fontSize: "min(3vw, 11px)", fontWeight: "bold", opacity: ".9" }}>{props.label ? props.label : ""}</span>
          <span
            style={{
              color: props.type !== "notes" ? "black" : "#555",
              lineHeight: props.type !== "notes" ? "min(4vw, 20px)" : "min(3.3vw, 14px)",
              fontSize: props.label !== "Παρατηρήσεις" ? "min(4vw, 20px)" : "min(3vw, 14px)",
              fontWeight: "400",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: props.object[props.field]?.toString().split("\n").join("<br/>") }} />
          </span>
        </div>
        <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }}>
          {props.type === "phone" && props.object && props.object[props.field] && (
            <PhoneIcon
              style={{ margin: "0px", fontSize: "20px" }}
              onClick={() => {
                window.showAlert(<a href={`tel:${props.object[props.field]}`}>{props.object[props.field]}</a>, "Τηλεφωνική κλήση", "Ακυρο");
              }}
            />
          )}

          {props.type === "email" && props.object && props.object[props.field] && (
            <Email
              style={{ margin: "0px", fontSize: "20px" }}
              onClick={() => {
                window.showAlert(<a href={`mailto:${props.object[props.field]}`}>{props.object[props.field]}</a>, "Αποστολή email", "Ακυρο");
              }}
            />
          )}
        </div>
      </div>
    </Item>
  );
}

export function FormRow({ props, children }) {
  return <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: 0, padding: 0 }}>{children}</div>;
}

export function CheckBox(props) {
  return (
    <div
      style={{ fontSize: "15px", padding: "0px" }}
      onClick={(event) => {
        console.log(event.target.checked);
        props.setObject((prevObject) => {
          var tmp = { ...prevObject };
          tmp[props.field] = event.target.checked;
          return tmp;
        });
      }}
    >
      {props.label}
      <Checkbox checked={props.object ? props.object[props.field] : false} sx={{ margin: 0, padding: "4px", "& .MuiSvgIcon-root": { fontSize: 18 } }} />
    </div>
  );
}

export function SelectInput(props) {
  const variant = "filled";

  const [id] = useState(uuid());
  return (
    <div style={{ flex: props.flex, display: "flex", flexDirection: "column", margin: props.margin ? props.margin : "5px 5px 15px 5px" }}>
      <FormControl {...props} variant={variant} size="small" fullWidth>
        <InputLabel shrink={true} color="success">
          {props.label}
        </InputLabel>
        <Select
          {...props}
          disabled={props.disabled ? props.disabled : false}
          id={id}
          variant={variant}
          value={props.object ? props.object[props.field] : ""}
          onChange={(event) => {
            props.setObject((prevObject) => {
              var tmp = { ...prevObject };

              tmp[props.field] = event.target.value;

              return tmp;
            });
          }}
        >
          {props.options && props.options.map((o) => <MenuItem value={o.id}>{o.name}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
}
