import React, { Fragment, useEffect, useState, useContext } from "react";

import { List, ListItem, ListItemButton, ListItemText, SpeedDial, SpeedDialIcon, Avatar, ListItemAvatar, Checkbox, FormGroup, FormControlLabel } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import { getClientExtraData, getClientStatus, getClientDebt, getSortedClientList, getClientSubscriptions, getSubscriptionPlanData,clientHasInvoice } from "../../data/db";
import { AppContext } from "../../App";

export default function Clients(props) {
  const appState = useContext(AppContext);
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState([]);
  const [showInActive, setShowInActive] = React.useState(window.clientsShowInActive);
  const [filter, setFilter] = React.useState(window.clientsFilter ?? "");
  const [filteredClients, setFilteredClients] = React.useState(null);

  useEffect(() => {
    window.clientsFilter = filter;
    window.clientsShowInActive = showInActive;
    setFilteredClients(getSortedClientList(appState.clients, !showInActive, filter));
  }, [showInActive, appState.clients, filter]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div style={{ position: "absolute", verflow: "auto", top: "1vh", left: "10px", right: "10px", bottom: "1vh", overflow: "hidden", maxWidth: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>
        <div className="list-filters" style={{ flexShrink: "1" }}>
          <input
            placeholder="φίλτρο"
            type="text"
            className="list-filter"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
          {filter && filter !== "" && (
            <div
              className="list-clear-filter"
              onClick={() => {
                setFilter("");
              }}
            >
              X
            </div>
          )}
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size="small" onChange={() => setShowInActive((prev) => !prev)} checked={showInActive} style={{ padding: "4px 5px" }} />}
              label="Ανενεργoί"
              labelPlacement="start"
              color="default"
            />
          </FormGroup>
          <div className="list-counter">{filteredClients?.length}</div>
        </div>
        <div style={{ flexGrow: "1", maxHeight: "100%", overflow: "auto" }}>
          {filteredClients != null && (
            <List dense sx={{ padding: "0", minWidth: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
              {filteredClients.map((client, index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                return (
                  <Fragment key={index}>
                    <ClientListItem client={client} />
                    <div style={{ minHeight: "min(2vw,4px)", backgroundColor: "gray" }}></div>
                  </Fragment>
                );
              })}
            </List>
          )}
        </div>
      </div>
      {/*temporarily disabled save*/}
      {/* <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "absolute", bottom: 16, right: 16, opacity: "0.7" }}
        icon={<SpeedDialIcon />}
        onClick={() => {
          navigate(`/client/`);
          //saveClient({ fname: "test 2", lname: "test 1" })
        }}
      ></SpeedDial> */}
    </div>
  );
}

function ClientListItem(props) {
  const navigate = useNavigate();
  const client = props.client;
  const [subscription, setSubscription] = useState(undefined);
  const [expanted, setExpanted] = useState(false);

  useEffect(() => {
    if (expanted) {
      const s = getClientSubscriptions(client)[0];
      if (s) setSubscription({ ...s, data: getSubscriptionPlanData(s) });
      else setSubscription(null);
    }
  }, [props, expanted]);

  return (

    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flexGrow: "1" }}>
          <div sx={{ padding: "0", minWidth: "100%", maxWidth: "100%" }} key={client.id}>
            <div
              style={{ userSelect: "none", cursor: "pointer", padding: "0", display: "flex", flexDirection: "row", maxWidth: "100%", width: "100%", justifyContent: "end" }}
              onClick={() => {
                navigate(`/clientInfo/${client.id}`);
              }}
            >
              <div>
                <div style={{ minWidth: "8px", maxWidth: "8px", maxHeight: "10px", backgroundColor: getClientStatus(client).color, margin: "0px 0px 3px 2px", padding: "0 0 0 0" }}>&nbsp;</div>
                {clientHasInvoice(client) && (
                  <div style={{ minWidth: "8px", maxWidth: "8px", maxHeight: "10px", minHeight: "30px", backgroundColor: "#990", margin: "0px 0px 3px 2px", padding: "0 0 0 0" }}>&nbsp;</div>
                )}
              </div>
              <div style={{ padding: "10px 5px 10px 10px", flex: "10", display: "flex", flexDirection: "row", alignContent: "space-between", width: "calc(100% - 500px)", alignItems: "center" }}>
                <div style={{ flexGrow: "1", fontSize: "min(4.6vmin, 20px)", fontWeight: "400", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", letterSpacing: "-.6px" }}>
                  {`${getClientExtraData(client).fullName}`}
                </div>

                <div style={{ letterSpacing: "-0.5px", fontSize: "min(4vw, 17px)" }}>
                  <span style={{ color: "red" }}>{getClientDebt(client, true, 0).replace("€", "€ ")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ padding: "10px 5px", cursor: "pointer" }}
          onClick={(e) => {
            setExpanted((prev) => !prev);
          }}
        >
          {!expanted && <ExpandMoreIcon />}
          {expanted && <ExpandLessIcon />}
        </div>
      </div>

      {expanted && (
        <div style={{ display: "flex", flexDirection: "column", border: "0px solid red", fontSize: "14px", padding: "0px 15px 10px 15px" }}>
          <div
            onClick={() => {
              navigate(`/clientInfo/${client.id}`);
            }}
          >
            {subscription === null && <div style={{ fontSize: "13px" }}>Δεν υπάρχουν συνδρομές</div>}
            {subscription && (
              <>
                <div style={{ color: "#00a" }}>{subscription.data.displaName}</div>
                <div style={{ lineHeight: "20px", color: "black", fontSize: "13px", display: "flex", justifyContent: "space-between" }}>
                  <div>Έναρξη: {moment(subscription.from.toDate()).format("DD/MM/yyyy")}</div>
                  <div>
                    {subscription.to && <>Λήξη: {moment(subscription.to?.toDate()).format("DD/MM/yyyy")}</>}
                    {!subscription.to && <div style={{ color: "#00f" }}>{subscription.data.displaynextCharge}</div>}
                  </div>
                </div>
                {subscription.data.displayPlanReduction && <div style={{ fontSize: "12px", textAlign: "end" }}>{subscription.data.displayPlanReduction}</div>}
                <div style={{ fontSize: "13px", textAlign: "end" }}>{subscription.data.displayCost}</div>
              </>
            )}
            {client.paymentsSum && client.paymentsSum !== 0 && (
              <div style={{ color: "green", lineHeight: "20px", fontSize: "13px", textAlign: "left" }}>Πληρωμές: €{client.paymentsSum.toLocaleString("el-GR")}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
