import React, { Fragment, useEffect, useState, useContext } from "react";

import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    SpeedDial,
    SpeedDialIcon,
    Avatar,
    ListItemAvatar,
    Checkbox,
    FormGroup,
    FormControlLabel

} from '@mui/material';

import { deepOrange, deepPurple } from '@mui/material/colors';

import { useNavigate } from "react-router-dom";
import { getClientExtraData, roundNumber, getPlanFullName, getPlanExtra, getPlanColor, getSortedPlansList } from '../../data/db'
import { AppContext } from "../../App";



export default function Plans(props) {

    const appState = useContext(AppContext);
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState([]);
    const [showInActive, setShowInActive] = React.useState(window.plansShowInActive);
    const [filter, setFilter] = React.useState(window.plansFilter ?? '');
    const [filteredPlans, setFilteredPlans] = React.useState(null);

    useEffect(() => {
        window.plansFilter = filter;
        window.plansShowInActive = showInActive;
        setFilteredPlans(getSortedPlansList(appState.plans, !showInActive, filter));
    }, [showInActive, appState.plans, filter])


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };







    return (

        <div style={{ position: "absolute", verflow: "auto", top: "1vh", left: "10px", right: "10px", bottom: "1vh", overflow: "hidden", maxWidth: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>

                <div className="list-filters" style={{ flexShrink: "1" }}>

                    <input placeholder="φίλτρο" type="text" className="list-filter" value={filter} onChange={(e => {

                        setFilter(e.target.value)
                    })} />
                    {filter && filter !== '' &&
                        <div className="list-clear-filter" onClick={() => { setFilter('') }}>X</div>
                    }
                    <FormGroup >
                        <FormControlLabel
                            control={<Checkbox

                                size="small"
                                onChange={() => setShowInActive(prev => !prev)}
                                checked={showInActive}
                                style={{ padding: "4px 5px" }}


                            />} label="Ανενεργά" labelPlacement="start" color="default" />

                    </FormGroup>
                    <div className="list-counter">          {filteredPlans?.length}</div>

                </div>
                <div style={{ flexGrow: "1", maxHeight: "100%", overflow: "auto" }}>
                    {filteredPlans != null &&
                        <List dense sx={{ padding: "0", minWidth: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}>
                            {filteredPlans.map((plan, index) => {
                                const labelId = `checkbox-list-secondary-label-${index}`;
                                return (<Fragment key={index}>


                                    <ListItem sx={{ padding: "1vw", minWidth: "100%", maxWidth: "100%", height: "min(11vmin, 50px)", minHeight: "15px" }}
                                        key={plan.id}


                                        disablePadding
                                    >
                                        <ListItemButton style={{ padding: "min(1vw, 5px)", display: "flex", flexDirection: "row", maxWidth: "100%", width: "100%", justifyContent: "end" }}
                                            onClick={() => {
                                                navigate(`/plan/${plan.id}`);
                                            }}>
                                            <div style={{ lineHeight:"20px", margin: "0 6px 0 0", padding: "0", backgroundColor: getPlanColor(plan) }}>
                                            &nbsp;&nbsp;
                                            </div>
                                            <div style={{ flex: "10", display: "flex", flexDirection: "row", alignContent: "space-between", width: "calc(100% - 500px)", alignItems: "center" }}>
                                                <div style={{ flexGrow: "1", fontSize: "min(4.6vmin, 20px)", fontWeight: '400', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", letterSpacing: "-.6px" }}>
                                                    {`${getPlanFullName(plan)}`}
                                                </div>

                                                <div style={{ letterSpacing: "-0.5px", fontSize: "min(4vw, 17px)" }}>
                                                    {`${getPlanExtra(plan)}`}
                                                </div>
                                            </div>
                                        </ListItemButton>
                                    </ListItem>
                                    <div style={{ minHeight: "min(2vw,4px)", backgroundColor: 'gray' }}></div>
                                </Fragment>
                                );
                            })}
                        </List>
                    }
                </div>

            </div>
            {/*temporarily disabled save*/}
            {/* <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, right: 16, opacity: "0.7" }}
                icon={<SpeedDialIcon />}
                onClick={() => {
                    navigate(`/plan/`);
                    //saveClient({ fname: "test 2", lname: "test 1" })
                }}
            >

            </SpeedDial> */}
        </div>)
}