import React, { useEffect, useState, useContext } from "react";
import { Box, Card, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import "moment/locale/el";
import { AppContext } from "../../App";
import { maxWidth, padding } from "@mui/system";

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';


import { getClientExtraData, getSortedClientList, nameReplace, getClientXDebt } from "../../data/db";


export default function Main() {

    const appState = useContext(AppContext);
    const navigate = useNavigate();


    const dayCounter = appState.dayCounter ? appState.dayCounter : 0;
    const setDayCounter = (counter) => window.setAppState((prev) => ({ ...prev, dayCounter: counter }))

    const monthCounter = appState.monthCounter ? appState.monthCounter : 0;
    const setMonthCounter = (counter) => window.setAppState((prev) => ({ ...prev, monthCounter: counter }))

    const getMonthSums = () => {
        if (appState.clients && appState.subscriptions && appState.payments) {
            let mounthPayments = appState.payments.filter(p => moment(p.date.toDate()).format("YYYY/MM") === moment(Date.now()).add(-monthCounter, 'months').format("YYYY/MM"))
            let mounthNewSubscriptions = appState.subscriptions.filter(s => moment(s.from.toDate()).format("YYYY/MM") === moment(Date.now()).add(-monthCounter, 'months').format("YYYY/MM"))

            let uniqueClientFromPaymentsCount = [...new Set(mounthPayments.map(p => p.client))].length
            let uniqueClientFromSubscriptions = [...new Set(mounthNewSubscriptions.map(p => p.client))].length

            let today = new Date();
            let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            firstDay.setMonth(firstDay.getMonth() - monthCounter);

            // console.log(moment(firstDay).format('DD/MM/yyyy'));
            let beforeMountSubscriptions = appState.subscriptions.filter(p => p.from.toDate() < firstDay).map(p => p.client);
            let newClients = [...new Set(mounthNewSubscriptions.map(s => s.client).filter(c => !beforeMountSubscriptions.includes(c)))];

            // console.log(beforeMounthPresences)
            // console.log(newClients)
            // console.log(mounthPresences.map(p => p.client.path + ' ' + moment(p.date).format('DD/MM/yyyy')))

            return <>
                {newClients.length > 0 &&
                    <div style={{ fontSize: "min(3.8vw,14px)" }}>Νέοι πελάτες: {newClients.length}</div>
                }
                {newClients.length === 0 &&
                    <div style={{ fontSize: "min(3.8vw,14px)" }}>Δεν υπάρχουν νέοι πελάτες</div>
                }
                {uniqueClientFromPaymentsCount > 0 &&
                    <>
                        <div style={{ fontSize: "min(3.8vw,14px)", marginTop: "10px" }}>Πληρωμές: <span style={{ fontWeight: "500" }}>€{mounthPayments.reduce((partialSum, a) => partialSum + a.amount, 0)}</span> από {uniqueClientFromPaymentsCount} {uniqueClientFromPaymentsCount > 1 ? 'πελάτες' : 'πελάτη'}</div>
                        {[...new Set(mounthPayments.map(p => p.user))].map((u, index) => <div key={index} style={{ paddingLeft: "5px", fontSize: "min(3.8vw,14px)" }}>{nameReplace(u)}: €{mounthPayments.filter(p => p.user === u).reduce((partialSum, a) => partialSum + a.amount, 0)} </div>)}
                    </>
                }
                {uniqueClientFromPaymentsCount === 0 &&
                    <div style={{ fontSize: "min(3.8vw,14px)", marginTop: "10px" }}>Δεν υπάρχουν πληρωμές</div>
                }


                {uniqueClientFromSubscriptions > 0 &&
                    <>
                        <div style={{ fontSize: "min(3.8vw,14px)", marginTop: "10px" }}>Νέες Συνδρομές: {mounthNewSubscriptions.length} από {uniqueClientFromSubscriptions}  {uniqueClientFromSubscriptions > 1 ? 'πελάτες' : 'πελάτη'}</div>
                        {[...new Set(mounthNewSubscriptions.map(s => s.user))].map((u, index) => <div key={index} style={{ paddingLeft: "5px", fontSize: "min(3.8vw,14px)" }}>{nameReplace(u)}: {mounthNewSubscriptions.filter(s => s.user === u).length} </div>)}

                    </>
                }
                {uniqueClientFromSubscriptions === 0 &&
                    <div style={{ fontSize: "min(3.8vw,14px)", marginTop: "10px" }}>Δεν υπάρχουν νέες συνδρομές</div>
                }





            </>
        }
        else return <>...</>
    }

    const clientsWithDebtList = () => {

        if (appState.clients) {

            let xClients = getSortedClientList(appState.clients).map(c => ({ ...c, debt: getClientXDebt(c, false) })).filter(c => c.debt > 0);
            if (xClients.length) {
                return <>
                    <div style={{ fontSize: "min(3.8vw,14px)" }}>Χρέος: <span style={{ color: "red", fontWeight: "500" }}>€{xClients.reduce((partialSum, a) => partialSum + a.debt, 0)}</span> από {xClients.length} {xClients.length > 1 ? 'πελάτες' : 'πελάτη'} </div>
                    {xClients.map((c, index) =>
                        <div
                            key={index}
                            onClick={() => {
                                navigate(`/clientInfo/${c.id}`);
                            }}
                            style={{ paddingLeft: "5px", fontSize: "min(3.8vw,14px)" }}>
                            {getClientExtraData(c, true).fullNameWithNums}
                        </div>
                    )}
                </>
            }
            else {
                return <div />
            }

        }
        else {

            return <div />
        }
    }


    const getPresencesList = () => {

        let msg;

        if (appState.subscriptions) {
            const subscriptions = appState.subscriptions.filter(p => moment(p.date).format("YYYY/MM/DD") === moment(Date.now()).add(-dayCounter, 'days').format("YYYY/MM/DD"))
            let clientList = [];

            subscriptions.forEach(p => {
                const client = appState.clients.filter(c => c.id === p.client)[0]
                if (client) {

                    clientList.push(client)
                }
            });

            if (clientList.length > 0) msg = <>{getSortedClientList(clientList).map((c, index) =>
                <div style={{ fontSize: "min(3.8vw,14px)", cursor: "pointer" }}
                    onClick={() => {
                        navigate(`/clientInfo/${c.id}`);
                    }}
                    key={index}>{getClientExtraData(c, true).fullNameWithNums}<br /></div>
            )
            }  <div style={{ marginTop: "10px", fontSize: "min(3.8vw,14px)" }}>({clientList.length} {clientList.length > 1 ? 'πελάτες' : 'πελάτης'})</div></>
        }

        if (msg) return msg; else return <>Δεν υπάρχουν παρουσίες</>
    }



    return (
        <div style={{ position: "absolute", verflow: "auto", top: "2vh", left: "10px", right: "10px", bottom: "1vh", overflow: "auto" }}>
            {window.installable &&
                <Card style={{ padding: "20px", marginBottom: "10px", display: "flex", justifyContent: "center" }}>
                    <Button size="small" variant="outlined" onClick={window.handleInstallClick}>
                        Εγκατασταση Εφαρμογης
                    </Button>
                </Card>
            }

            <Card sx={{ userSelect: "none", cursor: "pointer", padding: "20px", marginBottom: "10px", backgroundColor: "#ffffc0" }}

            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "10px" }} onClick={() => {
                    navigate("/dashboard/1");
                }}>
                    Πελάτες: {appState.clients ? appState.clients.filter(c => c.status == 1 || c.status == 2).length : "..."} ενεργοί από {appState.clients ? appState.clients.length : "..."} συνολικά
                </div>
                {clientsWithDebtList()}
            </Card>
            <Card sx={{ userSelect: "none", cursor: "default", padding: "20px", marginBottom: "10px", backgroundColor: "#e8e8ff" }}

            >

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "10px" }}>
                    <div style={{ fontSize: "min(4.4vw,16px)", flexGrow: "1", fontWeight: "500" }}>Σύνολα μήνα</div>
                    <ArrowCircleLeftOutlinedIcon style={{ opacity: "0.7" }}
                        onClick={() => { setMonthCounter(monthCounter + 1) }}
                    />
                    <div>&nbsp;{moment(Date.now()).add(-monthCounter, 'month').format("MMM yyyy")}&nbsp;</div>
                    <ArrowCircleRightOutlinedIcon style={{ opacity: "0.7" }}
                        onClick={() => { setMonthCounter(monthCounter > 0 ? monthCounter - 1 : 0) }}
                    />
                </div>

                {getMonthSums()}
            </Card>

            
        </div>)

}





