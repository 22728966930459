import React, { useEffect, useState, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import ArchiveIcon from '@mui/icons-material/Archive';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentHistory from '@mui/icons-material/History';

import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import { Menu, MenuItem, Divider, MenuList, ListItemIcon } from '@mui/material/';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../data/firebase";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../App";

import SubFormMain from './subForms/Main';
import SubFormClients from './subForms/Clients';
import SubFormPlans from './subForms/Plans';
import SubFormPayments from './subForms/Payments';
import SubFormLogs from './subForms/Logs';


import { fetchUserName, updateUserSetting } from '../data/db'
import Check from '@mui/icons-material/Check';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const drawerWidth = 240;



export default function Dashboard() {



    const appState = useContext(AppContext);
    const [user, loading, error] = useAuthState(auth);
    const { menuId } = useParams();
    const navigate = useNavigate();

    //menu start
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {

        if (e == "Αποσύνδεση") logout()
        if (e == "Ενημέρωση") window.location.reload(true);
        if (e == "Πρώτα το μικρό όνομα") {

            window.fnameFirst = !window.fnameFirst;
            updateUserSetting({ fnameFirst: window.fnameFirst })
            // window.setAppState((prev) => ({ ...prev, fnameFirst: window.fnameFirst }));
        }


        if (e == "test") test();



        setAnchorEl(null);

    };
    const options = [
        'Αποσύνδεση', '-', 'Ενημέρωση', '-', 'Πρώτα το μικρό όνομα' //, 'test'//, 'import'
    ];
    //menu ends


    useEffect(() => {
    }, [menuId])

    useEffect(async () => {
        if (loading) return;

        if (user === null) {
            if (appState.UserData) window.setAppState((prevAppState) => ({ ...prevAppState, UserData: undefined }))
            return navigate("/login");
        }


        if (!appState.UserData) {
            const userDoc = await fetchUserName(user);


            if (userDoc !== null) {
                const un = userDoc.name;
                window.dbRoot = userDoc.beta == true ? '/Debug/001/' : '';
                window.fnameFirst = userDoc.fnameFirst == true ? true : false;
                window.setAppState((prevAppState) => ({
                    ...prevAppState,
                    dbRoot: window.dbRoot,
                    UserData: { userDisplayName: un, Access: true, dbRoot: window.dbRoot }
                })
                )
            }
            else {
                window.setAppState((prevAppState) => ({ ...prevAppState, UserData: { userDisplayName: user.displayName, Access: false } }))
            }
        }


    }, [user, loading, error]);

    const theme = useTheme();


    return (
        <>
            {appState.UserData !== undefined && appState.UserData.Access === false &&

                <Box style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",

                }}>


                    <Card sx={{ maxWidth: 345 }}>

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Πρόβλημα
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Δεν έχετε δικαίωμα πρόσβασης στα δεδομένα της εφαρμογής με τον λογαριασμό {user.email}
                            </Typography>
                        </CardContent>
                        <CardActions style={{ justifyContent: "end", }}>
                            <Button size="small" onClick={() => window.location.reload(true)} >Επαναληψη</Button>
                            <Button size="small" onClick={logout} >Αποσυνδεση</Button>
                        </CardActions>
                    </Card>



                </Box>

            }
            {appState.UserData !== undefined && appState.UserData.Access === true &&

                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />

                    <AppBar className="app-bar" position="fixed">
                        <Toolbar style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", userSelect: "none" }}>
                            <div style={{ color: "#fa0", position: "absolute", right: "55px", top: 0 }}>
                                <span style={{ opacity: "1", font: "normal bold 18px/45px Arial" }} >{appState.dbRoot !== '' ? 'DEV' : ''}</span></div>
                            <span>
                                <span style={{ fontFamily: "Anton, sans-serif", fontSize: "22px", letterSpacing: ".5px", textShadow: "2px 2px black" }}>
                                    Level&nbsp;Up
                                    {parseInt(menuId ? menuId : 0) === 1 && ' - Πελάτες'}
                                    {parseInt(menuId ? menuId : 0) === 2 && ' - Πληρωμές'}
                                    {parseInt(menuId ? menuId : 0) === 3 && ' - Πακέτα'}
                                    {parseInt(menuId ? menuId : 0) === 4 && ' - Ιστορικό'}
                                </span>
                                {parseInt(menuId ? menuId : 0) === 0 &&
                                    <span style={{ opacity: "0.5", fontSize: "14px" }} >&nbsp;&nbsp;{window.version}&nbsp;{(appState.UserData.userDisplayName ? appState.UserData.userDisplayName : (appState.user.DisplayName != null ? appState.user.DisplayName : '')).split(' ')[0]}</span>
                                }
                            </span>
                            {/* <span style={{ marginTop: "0px", fontFamily: "sans-serif", fontSize: "15px", color: "#bbf", textShadow: "1px 1px black" }}>
                                &nbsp;     {appState.UserData.Name}
                            </span> */}

                            <Avatar
                                sx={{ width: 32, height: 32, border: "2px solid #aa0", marginLeft: "15px" }}
                                alt={user.displayName}
                                src={user.photoURL}
                                onClick={handleClick}
                            />

                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {options.map((option, index) => (
                                    <MenuList dense key={index}>
                                        {option === '-' && <Divider key={index} />}
                                        {option !== '-' &&
                                            <MenuItem key={index} onClick={(event) => handleClose(option, event)}>
                                                {option === 'Πρώτα το μικρό όνομα' && window.fnameFirst === true &&
                                                    <ListItemIcon>
                                                        <Check />
                                                    </ListItemIcon>}
                                                {option}
                                            </MenuItem>
                                        }
                                    </MenuList>
                                ))}
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ padding: "10px", position: 'fixed', top: "55px", paddingTop: "15px", left: 0, right: 0, bottom: "56px", backgroundColor: "gray" }} elevation={3}>
                        {parseInt(menuId ? menuId : 0) === 0 && <SubFormMain />}
                        {parseInt(menuId ? menuId : 0) === 1 && <SubFormClients />}
                        {parseInt(menuId ? menuId : 0) === 3 && <SubFormPlans />}
                        {parseInt(menuId ? menuId : 0) === 2 && <SubFormPayments />}
                        {parseInt(menuId ? menuId : 0) === 4 && <SubFormLogs />}


                    </Box>

                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation

                            value={parseInt(menuId ? menuId : 0)}
                            onChange={(event, newValue) => {
                                navigate(`/dashboard/${newValue}`)

                            }}
                        >
                            <BottomNavigationAction icon={<HomeIcon />} />
                            <BottomNavigationAction icon={<GroupIcon />} />
                            <BottomNavigationAction icon={<PaymentIcon />} />
                            <BottomNavigationAction icon={<FitnessCenter />} />
                            <BottomNavigationAction icon={<PaymentHistory />} />

                        </BottomNavigation>
                    </Paper>
                </Box>
            }
        </>
    );
}

