import React, { useEffect, useState, useContext, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText, Box, SpeedDial, SpeedDialIcon, SpeedDialAction, Toolbar, Typography, IconButton, AppBar, Slide, Button } from "@mui/material";
import { Timestamp } from "firebase/firestore";

import { AppContext } from "../../App";

import BackIcon from "@mui/icons-material/KeyboardBackspace";

import ListIcon from "@mui/icons-material/List";
import PaymentIcon from "@mui/icons-material/Payment";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { FormRow, Label, Item, TextInput } from "../controls/Inputs";

import { fetchDoc, saveDoc, delDoc, getClientExtraData, getSubscriptionPlanData, getClientDebt, getClientSubscriptions, getClientStatus, dateDiffString, getClientFullName } from "../../data/db";

import EditIcon from "@mui/icons-material/Edit";

import moment from "moment";
import "moment/locale/el";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientInfo(props) {
  const { id } = useParams();
  const appState = useContext(AppContext);
  const navigate = useNavigate();

  const [doc, setDoc] = useState(null);

  useEffect(() => {
    if (appState.dbRoot == undefined) navigate("/dashboard/1");
  }, []);

  useEffect(() => {
    if (id && appState.dbRoot !== undefined)
      fetchDoc(id, "clients").then((doc) => {
        if (doc) setDoc(doc);
        else navigate(-1);
      });
  }, [id]);

  useEffect(() => {
    if (id) {
      if (appState.dbRoot !== undefined)
        fetchDoc(id, "clients").then((dbDoc) => {
          if (dbDoc) {
            if (JSON.stringify(dbDoc) !== JSON.stringify(doc)) {
              setDoc(dbDoc);
            }
          } else navigate(-1);
        });
    }
  }, [appState]);

  const clientSubscriptions = doc ? getClientSubscriptions(doc) : [];

  return (
    <>
      {doc && (
        <>
          <Box
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "gray",
              overflow: "auto",
            }}
          >
            <div style={{ flex: "1", minHeight: "60px" }}>
              <AppBar>
                <Toolbar
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate(-1);
                    }}
                    edge="start"
                  >
                    <BackIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap component="div" style={{ flex: 50, userSelect: "none" }}>
                    {doc ? getClientExtraData(doc).fullName : ""}
                  </Typography>

                  <IconButton
                    color="inherit"
                    onClick={() => {
                      navigate(`/client/${id}`);
                    }}
                    style={{ paddingInline: ".5vh" }}
                  >
                    <EditIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            </div>
            <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
              <Box
                style={{
                  flex: "5",
                  padding: "5px 5px 0px 5px",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "auto",
                }}
              >
                {doc.inActive && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      minWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        margin: "2px 5px",
                        padding: "4px 6px",
                        backgroundColor: "black",
                        borderRadius: "4px",
                        fontSize: "14px",
                      }}
                    >
                      Ανενεργός
                    </div>
                  </div>
                )}

                <FormRow
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Label flex="4" field="lname" label="Επώνυμο" object={doc} setObject={setDoc} spellCheck={false} disabled />
                  <Label flex="4" field="fname" label="Όνομα" object={doc} setObject={setDoc} />
                </FormRow>
                {doc.email && doc.email.toString().trim() !== "" && (
                  <FormRow
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label flex="1" field="email" label="email" object={doc} setObject={setDoc} type="email" />
                  </FormRow>
                )}

                {(doc.mobile || doc.phone) && (
                  <FormRow
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label flex="4" field="mobile" label="Κινήτο τηλέφωνο" object={doc} setObject={setDoc} type="phone" />
                    <Label flex="4" field="phone" label="Σταθεό τηλέφωνο" object={doc} setObject={setDoc} type="phone" />
                  </FormRow>
                )}

                {doc.notes && doc.notes.toString().trim() !== "" && (
                  <FormRow
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label flex="1" field="notes" label="Παρατηρήσεις" object={doc} setObject={setDoc} type="notes" />
                  </FormRow>
                )}

                <Item
                  elevation="20"
                  style={{
                    flex: "1",
                    padding: "5px 5px 5px 10px",
                    margin: "3px 3px 5px 3px",
                    overflowY: "scrol",
                    lineHeight: "min(5vw,20px)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: getClientStatus(doc).color,
                        minWidth: "5px",
                        height: "12px",
                        margin: "0 5px 0 0",
                      }}
                    ></div>
                    <div style={{ fontSize: "12px", color: "#444" }}>{getClientStatus(doc).text}</div>
                  </div>
                </Item>

                <Item
                  elevation="20"
                  style={{
                    flex: "1",
                    padding: "5px 5px 5px 5px",
                    margin: "3px 3px 5px 3px",
                    overflowY: "scrol",
                    lineHeight: "min(5vw,20px)",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div>
                      <span style={{ fontWeight: "400", fontSize: "min(4vw,15px)" }}>Οφειλή:&nbsp; </span>
                    </div>
                    <div>
                      <span
                        style={{
                          color: "#F00",
                          fontWeight: "800",
                          fontSize: "min(4vw,15px)",
                        }}
                      >
                        {getClientDebt(doc, true, 2, true)}
                      </span>
                    </div>
                  </div>

                  {(doc.discount ? doc.discount : 0 !== 0) && (
                    <div style={{ textAlign: "end" }}>
                      <span style={{ fontWeight: "400", fontSize: "min(4vw,15px)" }}>Συνολική έκπτωση:</span>
                      <span
                        style={{
                          color: "#777",
                          fontWeight: "800",
                          fontSize: "min(4vw,15px)",
                        }}
                      >
                        {" "}
                        €{doc.discount ? doc.discount : 0}
                      </span>
                    </div>
                  )}
                </Item>
              </Box>
            </div>

            <div
              style={{
                flex: "10",
                overflowY: "clip",
                maxHeight: "100%",
                margin: "4px 8px 0px 8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  maxHeight: "100%",
                  minHeight: "100%",
                }}
              >
                <div
                  style={{
                    flex: "5",
                    marginBottom: "20px",
                    marginRight: "5px",
                    maxWidth: "min(43vw, 250px)",
                  }}
                >
                  <Item
                    elevation="20"
                    style={{
                      padding: "0px  10px  0px  10px",
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "100%",
                      minHeight: "100%",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px  0px  10px  0px",
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "100%",
                        minHeight: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexShrink: "1",
                          paddingBottom: "3px",
                          margin: "6px 0 0px 0",
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "2px solid #888",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "700",
                            color: "green",
                            fontSize: "min(3.4vw,14px)",
                          }}
                        >
                          Πληρωμές
                        </span>
                        <span style={{ fontWeight: "700", color: "green", fontSize: "min(3.4vw,14px)" }}>€{doc.paymentsSum.toLocaleString("el-GR")}</span>
                      </div>
                      <div style={{ flexGrow: "1", minHeight: "100%", maxHeight: "100%", overflowY: "auto" }}>
                        {appState.payments != null && doc && doc.docRef && (
                          <List dense sx={{ padding: "0", marginBottom: "1px" }}>
                            {appState.payments
                              .filter((p) => p.client === doc.id)
                              .sort((a, b) => b.date.toDate() - a.date.toDate())
                              .map((payment, index) => {
                                return (
                                  <Fragment key={index}>
                                    <ListItem sx={{ padding: "0px", margin: "0px" }} key={index}>
                                      <ListItemButton
                                        sx={{
                                          padding: "0px",
                                          margin: "0px",
                                          minHeight: "35px",
                                          borderBottom: "2px solid #888",
                                        }}
                                        onClick={() => {
                                          window.showModal({
                                            Body: <Payment payment={payment} client={doc} />,
                                            Title: "Ενημέρωση Πληρωμής",
                                          });
                                        }}
                                      >
                                        <ListItemText
                                          id={payment.id}
                                          primary={
                                            <div
                                              style={{
                                                padding: "3px 0",
                                                border: "0px solid blue",
                                              }}
                                            >
                                              <div style={{ lineHeight: "min(3.2vw,14px)", display: "flex", justifyContent: "space-between", alignContent: "flex-start", margin: "2px 0 6px 0" }}>
                                                <span style={{ color: "#880", fontSize: "min(3vw,13px)" }}>{dateDiffString(payment.date.toDate())}</span>
                                                <span
                                                  style={{
                                                    color: "green",
                                                    fontSize: "min(3.6vw,14.5px)",
                                                    lineHeight: "min(3vw,17px)",
                                                  }}
                                                >
                                                  €{payment.amount}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  lineHeight: "min(3vw,13px)",
                                                  marginBottom: "2px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "min(3.2vw,14px)",
                                                  }}
                                                >
                                                  {moment(payment.date.toDate()).format("dddd DD/MM/yyyy")}
                                                </span>
                                              </div>
                                              {payment.idate && (
                                                <div
                                                  style={{
                                                    lineHeight: "min(3vw,13px)",
                                                    margin: "4px 0",

                                                    textAlign: "end",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,13px)",
                                                      color: "green",
                                                    }}
                                                  >
                                                    {` #${payment.inum} - ${moment(payment.idate?.toDate()).format("DD/MM/yyyy")} - €${payment.iamount}`}
                                                  </span>
                                                </div>
                                              )}

                                              {appState.nameReplace(payment.user) && appState.nameReplace(payment.user) !== "..." && (
                                                <div
                                                  style={{
                                                    border: "0px solid black",
                                                    margin: "0px 0 1px 0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,12px)",
                                                    }}
                                                  >
                                                    {appState.nameReplace(payment.user)}
                                                  </span>
                                                </div>
                                              )}
                                              {payment.notes && (
                                                <div
                                                  style={{
                                                    color: "#777",
                                                    margin: "7px 0 0 0",
                                                    padding: "3px 0 0 0 ",
                                                    borderTop: "1px dotted #aaa",
                                                    lineHeight: "min(3vw,14px)",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,13px)",
                                                    }}
                                                  >
                                                    {payment.notes}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          }
                                          primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: "medium",
                                          }}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  </Fragment>
                                );
                              })}
                          </List>
                        )}
                      </div>
                    </div>
                  </Item>
                </div>
                <div
                  style={{
                    flex: "6",
                    marginBottom: "20px",
                    marginLeft: "5px",
                    maxWidth: "500px",
                  }}
                >
                  <Item
                    elevation="20"
                    style={{
                      padding: "0px  10px  0px  10px",
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "100%",
                      minHeight: "100%",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px  0px  10px  0px",
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "100%",
                        minHeight: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexShrink: "1",
                          paddingBottom: "3px",
                          margin: "6px 0 0px 0",
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "2px solid #888",
                        }}
                      >
                        <span
                          style={{
                            color: "blue",
                            fontWeight: "700",
                            fontSize: "min(3.4vw,14px)",
                          }}
                        >
                          Συνδρομές
                        </span>
                        <span
                          style={{
                            color: "blue",
                            fontWeight: "700",
                            fontSize: "min(3.4vw,14px)",
                          }}
                        >
                          €{doc.subscriptionsSum.toLocaleString("el-GR")}
                        </span>
                      </div>
                      <div
                        style={{
                          flexGrow: "1",
                          minHeight: "100%",
                          maxHeight: "100%",
                          overflowY: "auto",
                        }}
                      >
                        {appState.subscriptions != null && doc && doc.docRef && (
                          <List dense sx={{ padding: "0", marginBottom: "1px" }}>
                            {clientSubscriptions
                              .sort((a, b) => b.date - a.date)
                              .map((s) => ({
                                ...s,
                                data: getSubscriptionPlanData(s),
                              }))
                              .map((subscription, index) => {
                                return (
                                  <Fragment key={index}>
                                    <ListItem sx={{ padding: "1px", margin: "0px" }} key={index}>
                                      <ListItemButton
                                        sx={{
                                          margin: "0px",
                                          padding: "0px",
                                          minHeight: "35px",
                                          borderBottom: "2px solid #888 !important",
                                        }}
                                        onClick={() => {
                                          navigate(`/subscription/${subscription.id}`);
                                        }}
                                      >
                                        <ListItemText
                                          id={subscription.id}
                                          primary={
                                            <div>
                                              <div
                                                style={{
                                                  color: "#00f",
                                                  padding: "0px 0 0px 0px",
                                                  lineHeight: "15px",
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  alignContent: "flex-start",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "min(3.2vw,15px)",
                                                    fontWeight: "300",
                                                  }}
                                                >
                                                  {subscription.data.displaName}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  padding: "0px 0 0px 0px",
                                                  lineHeight: "20px",
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  alignContent: "flex-start",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "min(3.2vw,15px)",
                                                    fontWeight: "300",
                                                  }}
                                                >
                                                  {`${moment(subscription.from?.toDate()).format("DD/MM/yyyy")}
                                                                                        ${subscription.to ? " - " + moment(subscription.to?.toDate()).format("DD/MM/yyyy") : ""}`}
                                                </span>
                                              </div>

                                              {!subscription.to && (
                                                <div
                                                  style={{
                                                    color: "#00a",
                                                    margin: "0px 0 1px 0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,12px)",
                                                    }}
                                                  >
                                                    {subscription.data.displaynextCharge}
                                                  </span>
                                                </div>
                                              )}

                                              {subscription.data.displayPriceReduction !== "" && (
                                                <div
                                                  style={{
                                                    margin: "0px 0 1px 0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,12px)",
                                                    }}
                                                  >
                                                    {subscription.data.displayPriceReduction}
                                                  </span>
                                                </div>
                                              )}
                                              {subscription.data.displayPlanReduction !== "" && (
                                                <div
                                                  style={{
                                                    margin: "0px 0 1px 0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,12px)",
                                                    }}
                                                  >
                                                    {subscription.data.displayPlanReduction}
                                                  </span>
                                                </div>
                                              )}
                                              {subscription.data.displayCost !== "" && (
                                                <div
                                                  style={{
                                                    color: "#aa0",
                                                    margin: "0px 0 1px 0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,12px)",
                                                    }}
                                                  >
                                                    {subscription.data.displayCost.toString().replace(" συνδομής", "")}
                                                  </span>
                                                </div>
                                              )}
                                              {subscription.notes && (
                                                <div
                                                  style={{
                                                    color: "#777",
                                                    marginTop: "5px",
                                                    padding: "3px 0px 3px 3px",
                                                    borderTop: "1px dotted #999",
                                                    lineHeight: "min(3vw,14px)",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "min(3vw,13px)",
                                                    }}
                                                  >
                                                    {subscription.notes}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          }
                                          primaryTypographyProps={{
                                            fontSize: 16,
                                            fontWeight: "medium",
                                          }}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  </Fragment>
                                );
                              })}
                          </List>
                        )}
                      </div>
                    </div>
                  </Item>
                </div>
              </div>
            </div>
          </Box>
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            style={{
              opacity: ".9",
              position: "absolute",
              bottom: 16,
              right: 16,
            }}
            icon={<SpeedDialIcon />}
          >
            {/*temporarily disabled save*/}
            {/* <SpeedDialAction
              key={0}
              tooltipOpen
              onClick={() => {
                navigate(`/subscription/client:${doc.id}`);
              }}
              icon={<ListIcon />}
              tooltipTitle={"Συνδρομή"}
            /> */}
            <SpeedDialAction
              key={1}
              icon={<PaymentIcon />}
              tooltipTitle={"Πληρωμή"}
              tooltipOpen
              onClick={() => {
                window.showModal({
                  Body: <Payment clientId={doc.id} client={doc} />,
                  Title: "Νέα πληρωμή",
                  
                });
              }}
            />
          </SpeedDial>
        </>
      )}
    </>
  );
}

export function Payment(props) {
  const appState = useContext(AppContext);
  useEffect(() => {}, [props]);

  const [doc, setDoc] = useState(
    props.payment
      ? props.payment
      : {
          client: props.clientId,
          date: Timestamp.fromDate(new Date()),
          user: appState.user.displayName,
        }
  );
  return (
    <>
      <TextInput margin="5px 5px 5px 5px" field="amount" label="Ποσό" object={doc} setObject={setDoc} type="number" />
      <div
        style={{
          display: "flex",
          margin: "0px 5px 0px 5px",
          justifyContent: "space-between",
        }}
      >
        {[20, 25, 30, 35, 40].map((a, index) => (
          <Button
            variant="contained"
            color={doc.amount === a ? "success" : "primary"}
            style={{
              margin: "2px 0px 10px 0px",
              opacity: "0.95",
              minWidth: "17%",
              padding: "0",
              fontSize: "1rem",
            }}
            size="small"
            key={index}
            onClick={() => {
              setDoc((prevObject) => {
                var tmp = { ...prevObject };
                tmp.amount = a;
                return tmp;
              });
            }}
          >
            {a}
          </Button>
        ))}
      </div>

      <TextInput type="date" field="date" label="Ημερομηνία" object={doc} setObject={setDoc} />

      <Button
        variant="contained"
        //clear upercase to style

        style={{
          margin: "5px 5px 5px 5px",
          opacity: "0.95",
          minWidth: "17%",
          padding: "0",
          fontSize: "1rem",
          textTransform: "none",
          padding: "5px 10px 5px 10px",
          float: "right",
        }}
        size="small"
        onClick={() => {
          setDoc((prevObject) => {
            var tmp = { ...prevObject };
            if (tmp.idate) {
              tmp.iamount = null;
              tmp.idate = null;
              tmp.inum = "";
            } else {
              tmp.iamount = tmp.amount;
              //set idate to today
              tmp.idate = Timestamp.fromDate(new Date());

              tmp.inum = "";
            }
            return tmp;
          });
        }}
      >
        {doc.idate ? "Διαγραφή Παραστατικού" : "Έκδοση Παραστατικού"}
      </Button>

      {doc.idate && (
        <>
          <TextInput margin="5px 5px 5px 5px" field="inum" label="Αριθμός Παραστατικού" object={doc} setObject={setDoc} type="text" />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 3 }}>
              <TextInput type="date" field="idate" label="Ημερομηνία Παραστατικού" object={doc} setObject={setDoc} />
            </div>
            <div style={{ flex: 2 }}>
              <TextInput margin="5px 5px 5px 5px" field="iamount" label="Ποσό Παραστατικού" object={doc} setObject={setDoc} type="number" />
            </div>
          </div>
        </>
      )}
      <TextInput field="notes" label="Παρατηρήσεις" object={doc} setObject={setDoc} rows={3} />

      <div
        style={{
          display: "flex",
          flexDirection: "row ",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => {
            window.setAppState((prevAppState) => ({
              ...prevAppState,
              ModalData: undefined,
            }));
          }}
        >
          Ακυρο
        </Button>
        {doc.id && (
          <Button
            onClick={() => {
              window.showDialog({
                Message: "Να διαγραφη πλήρωμη;",
                onAgree: () => {
                  delDoc(doc.id, "payments", doc, appState.user)
                    .then((refId) => {
                      window.setAppState((prevAppState) => ({
                        ...prevAppState,
                        ModalData: undefined,
                      }));
                    })
                    .catch((error) => {
                      window.showAlert(`${error}`);
                    });
                },
              });

              // delDoc(doc.id, 'subscriptions', doc, appState.user).then((refId) => {
              //     window.setAppState((prevAppState) => ({ ...prevAppState, ModalData: undefined }));
              // }).catch((error) => {
              //     window.showAlert(`${error}`)
              // })
            }}
          >
            Διαγραφη
          </Button>
        )}
        <Button
          onClick={() => {
            saveDoc(doc.id, "Payments", doc, appState.user)
              .then((refId) => {
                window.setAppState((prevAppState) => ({
                  ...prevAppState,
                  ModalData: undefined,
                }));
              })
              .catch((error) => {
                window.showAlert(`${error}`);
              });
          }}
        >
          Καταχωριση
        </Button>
      </div>
    </>
  );
}

function Subscriptions(props) {
  const appState = useContext(AppContext);
  useEffect(() => {}, [props]);

  const [doc, setDoc] = useState(
    props.subscription
      ? props.subscription
      : {
          client: props.clientId,
          date: Date.now(),
          user: appState.user.displayName,
        }
  );
  return (
    <>
      <TextInput type="date" field="date" label="Ημερομηνία" object={doc} setObject={setDoc} />

      <TextInput field="notes" label="Παρατηρήσεις" object={doc} setObject={setDoc} rows={3} />

      <div
        style={{
          display: "flex",
          flexDirection: "row ",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => {
            window.setAppState((prevAppState) => ({
              ...prevAppState,
              ModalData: undefined,
            }));
          }}
        >
          Ακυρο
        </Button>
        {doc.id && (
          <Button
            onClick={() => {
              window.showDialog({
                Message: (
                  <>
                    Να πραγματοποιηθεί η διαγραφή της συνδρομής:
                    <p>
                      {getClientExtraData(props.client).fullName}
                      <br />
                      {moment(doc.date).format("dddd D MMMM yyyy")}
                    </p>
                  </>
                ),
                onAgree: () => {
                  delDoc(doc.id, "subscriptions", doc, appState.user)
                    .then((refId) => {
                      window.setAppState((prevAppState) => ({
                        ...prevAppState,
                        ModalData: undefined,
                      }));
                    })
                    .catch((error) => {
                      window.showAlert(`${error}`);
                    });
                },
              });

              // delDoc(doc.id, 'subscriptions', doc, appState.user).then((refId) => {
              //     window.setAppState((prevAppState) => ({ ...prevAppState, ModalData: undefined }));
              // }).catch((error) => {
              //     window.showAlert(`${error}`)
              // })
            }}
          >
            Διαγραφη
          </Button>
        )}
        <Button
          onClick={() => {
            saveDoc(doc.id, "Subscriptions", doc, appState.user)
              .then((refId) => {
                window.setAppState((prevAppState) => ({
                  ...prevAppState,
                  ModalData: undefined,
                }));
              })
              .catch((error) => {
                window.showAlert(`${error}`);
              });
          }}
        >
          Καταχωριση
        </Button>
      </div>
    </>
  );
}
