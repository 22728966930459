import "./forms.css";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Paper } from '@mui/material';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { AppContext } from "../../App";
import { fetchDoc, saveDoc, delDoc, getPlanExtra, getPlanFullName } from '../../data/db'
import { TextInput, DateInput, FormRow, CheckBox } from "../controls/Inputs"


import { SavedSearchOutlined, UnfoldLess } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Plan(props) {

    const appState = useContext(AppContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [doc, setDoc] = useState(null)

    useEffect(() => {
        if (appState.dbRoot === undefined) navigate('/dashboard/1')
    }, [])


    useEffect(() => {
        if (id && appState.dbRoot !== undefined) fetchDoc(id, 'plans').then(setDoc)
    }, [id])


    return (

        <Box style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", backgroundColor: "#ffffea", overflow: "auto" }}
        >
            <div style={{ flex: "1", minHeight: "60px" }} >
                <AppBar position="fixed">
                    <Toolbar style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                        <IconButton
                            color="inherit"
                            onClick={() => { navigate(-1) }}
                            edge="start">
                            <BackIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" style={{ flex: 50, userSelect: "none" }} >
                            {doc ? getPlanFullName(doc) : 'Νεος πακέτο'}

                        </Typography>

                        {id !== undefined &&
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    window.setAppState((appState) => (
                                        {
                                            ...appState,
                                            DialogData:
                                            {
                                                Message: "Να πραγματοποιηθεί η διαγραφή του πακέτου;",
                                                onAgree: () => {

                                                    delDoc(id, 'plans', doc, appState.user)
                                                        .then(() => navigate(-1))
                                                        .catch((error) => {
                                                            window.showAlert(`${error}`)
                                                        });
                                                }
                                            }
                                        }))

                                }}
                                style={{ paddingInline: "7px" }}

                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                if (!doc || doc.lname === undefined) {
                                    window.showAlert('Το επώνυμο είναι υποχραιωτικό!')
                                }
                                else {
                                    window.showDialog(
                                        {
                                            Message: "Να πραγματοποιηθεί η αποθήκευση του πακέτου;",
                                            ButtonExtraName: "Ναι & Επιστροφη",
                                            onAgree: () => {
                                                saveDoc(id, 'plans', doc, appState.user).then((refId) => {
                                                    if (!id) navigate(`/plan/${refId.id}`, { replace: true });
                                                }).catch((error) => {
                                                    window.showAlert(`${error}`)
                                                })
                                            },
                                            onExtraButton: () => {
                                                saveDoc(id, 'plans', doc, appState.user).then((refId) => {
                                                    navigate(-1);
                                                }).catch((error) => {
                                                    window.showAlert(`${error}`)
                                                })
                                            }
                                        }
                                    )
                                }
                            }}
                            style={{ paddingInline: "7px" }}
                        >
                            <SaveIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
            <Box style={{ padding: "0px 10px 10px 10px", marginTop: "7px", display: 'flex', flexDirection: "column", height: "100%", overflow: "auto" }}>

                <div style={{ minWidth: "100", display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>
                    <CheckBox field="inActive" label="Ανενεργό" object={doc} setObject={setDoc} />
                </div>

                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="4" field="name" label="Όνομα" object={doc} setObject={setDoc} />
                </FormRow>


                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="1" field="type" label="Τύπος" object={doc} setObject={setDoc} rows={1} />
                </FormRow>


                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="1" field="price" label="Τιμή" object={doc} setObject={setDoc} type="number" />
                    <TextInput flex="1" field="months" label="Μήνες" object={doc} setObject={setDoc} type="number" />
                    <TextInput flex="1" field="sessions" label="Παρουσίες" object={doc} setObject={setDoc} type="number" />
                </FormRow>


                <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <TextInput flex="1" field="notes" label="Παρατηρήσεις" object={doc} setObject={setDoc} rows={3} />
                </FormRow>


            </Box>

        </Box >
    )

}