import { auth, db, logout } from "../data/firebase";
import { query, collection, getDocs, where, setDoc, getDoc, doc, writeBatch, serverTimestamp, Timestamp, documentId, updateDoc } from "firebase/firestore";
import { useState } from "react";
import moment from "moment";
import "moment/locale/el";
import { mobileModel, osVersion, osName, browserName, browserVersion } from "react-device-detect";

export const nameReplace = (name) => {
  try {
    return name.replace("Stathis Blackbird", "Στάθης").replace("Spyros Georgiakakis", "Σπύρος").replace("Liana Papadopoulou", "Λιάνα");
  } catch (error) {
    return "Στάθης";
  }
};

export const fetchUserName = async (user) => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const doc = await getDocs(q);

    if (doc.docs[0].exists) return doc.docs[0].data();
    else return null;
  } catch (err) {
    console.error(err.toString());
    //alert("An error occured while fetching user data");

    //return user.displayName;
    return null;
  }
};

export const fetchDoc = async (uid, dbCollection) => {
  if (window.appState[dbCollection]) {
    //console.log('from appState')
    return window.appState[dbCollection].filter((d) => d.id === uid)[0];
  }

  dbCollection = window.dbRoot + dbCollection;

  const docRef = doc(db, dbCollection, uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), docRef };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return {};
  }
};

export const saveDoc = async (uid, dbCollection, document, user, batch) => {
  let original;
  let historyObject = {};
  historyObject.text = "";

  console.log(document, dbCollection);

  dbCollection = window.dbRoot + dbCollection;

  if (dbCollection === window.dbRoot + "Subscriptions") {
    if (document.extraDays === 0) {
      document.extraDays = null;
    }
    if (document.reduction === 0) {
      document.reduction = null;
    }

    document.cost = getSubscriptionCost(document);
  }

  if (dbCollection === window.dbRoot + "Payments") {
    if (document.notes == null) document.notes = "";

    historyObject.object = "Payment";
    historyObject.client = document.client;

    if (document.id) {
      original = window.appState.payments.filter((d) => d.id === document.id)[0];
      delete original.docRef;
      delete original.timestamp;

      if (original.date != document.date) {
        historyObject.text += `Προηγούμενη Ημερομηνία: ${moment(original.date.toDate()).format("DD/MM/yyyy")}\n`;
        historyObject.text += `Νέα Ημερομηνία: ${moment(document.date.toDate()).format("DD/MM/yyyy")}\n`;
      }
      if (original.amount != document.amount) {
        historyObject.text += `Προηγούμενο Ποσό: €${original.amount}\n`;
        historyObject.text += `Νέο Ποσό: €${document.amount}\n`;
      }

      if (original.notes != document.notes) {
        if (original.notes != null && original.notes.trim() != "") historyObject.text += `Προηγούμενες Παρατηρήσεις: ${original.notes}\n`;
        if (document.notes != null && document.notes.trim() != "") historyObject.text += `Νέες Παρατηρήσεις: ${document.notes}\n`;
      }

      if (original.idate != document.idate) {
        if (original.idate == null && document.idate != null) {
          historyObject.text += `Έκδοση Παραστατικού: #${document.inum} - ${moment(document.idate.toDate()).format("DD/MM/yyyy")} - €${document.iamount ?? 0}\n`;
        } else if (original.idate != null && document.idate == null) {
          historyObject.text += `Διαγραφή Παραστατικού: #${original.inum} - ${moment(original.idate.toDate()).format("DD/MM/yyyy")} - €${original.iamount ?? 0}\n`;
        } else {
          historyObject.text += `Προηγούμενη Ημερoμηνία Παραστατοκού: ${moment(original.idate.toDate()).format("DD/MM/yyyy")}\n`;
          historyObject.text += `Νέα Ημερoμηνία Παραστατοκού: ${moment(document.idate.toDate()).format("DD/MM/yyyy")}\n`;
        }
      } else {
        if (original.inum != document.inum) {
          historyObject.text += `Προηγούμενος Αριθμός Παραστατικού: ${original.inum}\n`;
          historyObject.text += `Νέος Αριθμός Παραστατικού: ${document.inum}\n`;
        }

        if (original.iamount != document.iamount) {
          historyObject.text += `Προηγούμενο Ποσό Παραστατικού: €${original.iamount}\n`;
          historyObject.text += `Νέο Ποσό Παραστατικού: €${document.iamount}\n`;
        }
      }
    } else {
      historyObject.text += `Πελάτης: ${getClientFullName(window.appState.clients.filter((d) => d.id === document.client)[0])}\n`;
      historyObject.text += `Ημερομηνία: ${moment(document.date.toDate()).format("DD/MM/yyyy")}\n`;
      historyObject.text += `Ποσό: €${document.amount}\n`;

      if (document.idate != null) historyObject.text += `Έκδοση Παραστατικού: #${document.inum} - ${moment(document.idate.toDate()).format("DD/MM/yyyy")} - €${document.iamount ?? 0}\n`;

      if (document.notes != null && document.notes.trim() != "") historyObject.text += `Παρατηρήσεις: ${document.notes}\n`;
    }

    if (!document || !document.amount) throw "Το ποσό είναι υποχραιωτικό!";
    if (!document || !document.date) {
      throw "Η ημερομηνία είναι υποχραιωτική!";
    } else {
      if (document.date > Date.now()) {
        throw "Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της σημερινής!";
      }
    }
  }

  // if (dbCollection === window.dbRoot + 'subscriptions') {

  //   if (!document || !document.date) {
  //     throw 'Η ημερομηνία είναι υποχραιωτική!';
  //   }
  //   else {
  //     if (document.date > Date.now()) {
  //       throw 'Η ημερομηνία δεν μπορεί να είναι μεταγενέστερη της σημερινής!';
  //     }
  //   }

  //   if (window.appState.subscriptions.filter(p => {
  //     return moment(p.date).format("YYYY/MM/DD") === moment(document.date).format("YYYY/MM/DD") && p.client.path === document.client.path && p.id != (document.id ? document.id : '')
  //   }).length > 0)
  //     throw 'Υπάρχει παρουσία για αυτή την ημέρα!';
  // }

  // /////////////////////////////////////////////////////////////////////////

  let type;
  const newBatch = batch === undefined || batch === null;
  if (newBatch) batch = writeBatch(db);

  var docToSave;
  if (dbCollection === window.dbRoot + "clients") {
    docToSave = await updateClientSums(document, true);
  } else {
    docToSave = { ...document };
  }

  let docRef;
  if (uid === undefined) {
    docRef = doc(collection(db, dbCollection));
    type = 1;
  } else {
    delete docToSave.docRef;
    docRef = doc(db, dbCollection, uid);
    type = 2;
  }
  console.log(type, newBatch, batch, docRef, docToSave, uid);
  batch.set(docRef, docToSave);

  if (dbCollection === window.dbRoot + "Payments") {
    historyObject.object = "Payment";
    historyObject.client = document.client;
  }

  historyObject.type = type;
  historyObject.uid = user.uid;
  historyObject.documentId = docRef.id;
  historyObject.timestamp = serverTimestamp();
  historyObject.user = user.displayName;
  historyObject.doc = JSON.stringify({ ...docToSave, timestamp: undefined });
  historyObject.prevDoc = original ? JSON.stringify(original) : null;

  docToSave.timestamp = serverTimestamp();

  const historyRef = doc(collection(db, window.dbRoot + "History"));
  batch.set(historyRef, historyObject);

  if (newBatch) await batch.commit();

  if (dbCollection === window.dbRoot + "Payments" || dbCollection === window.dbRoot + "Subscriptions") {
    await updateClientSums(document.client, false);
  }

  return docRef.id;
};

export const delDoc = async (uid, dbCollection, document, user, batch) => {
  dbCollection = window.dbRoot + dbCollection;
  console.log(uid, dbCollection, document, user, batch);

  // if (dbCollection === window.dbRoot + 'clients') {
  //   const clientRef = document.docRef
  //   if (clientRef) {
  //     const presencesQuery = query(collection(db, window.appState.dbRoot + "subscriptions"), where("client", "==", clientRef));
  //     const presencesSnapshot = await getDocs(presencesQuery);
  //     if (presencesSnapshot.docs.length > 0) throw 'Δεν μπορεί να διαγραφεί πελάτης που έχει καταχωρημένες παρουσίες!';
  //     const paymentsQuery = query(collection(db, window.appState.dbRoot + "payments"), where("client", "==", clientRef));
  //     const paymentsSnapshot = await getDocs(paymentsQuery);
  //     if (paymentsSnapshot.docs.length > 0) throw 'Δεν μπορεί να διαγραφεί πελάτης που έχει καταχωρημένες πληρωμές!';
  //   }
  // }

  let historyObject = {};
  historyObject.text = "";

  try {
    const newBatch = batch === undefined || batch === null;

    if (newBatch) batch = writeBatch(db);

    let docRef = document?.docRef;
    if (docRef === undefined) {
      docRef = doc(db, dbCollection, uid);
    }
    batch.delete(docRef);

    historyObject.type = 3;
    historyObject.uid = user.uid;
    historyObject.documentId = docRef.id;
    historyObject.timestamp = serverTimestamp();
    historyObject.user = user.displayName;
    historyObject.prevDoc = JSON.stringify({ ...document, timestamp: undefined });

    if (dbCollection === window.dbRoot + "payments") {
      historyObject.text += `Πελάτης: ${getClientFullName(window.appState.clients.filter((d) => d.id === document.client)[0])}\n`;
      historyObject.text += `Ημερομηνία: ${moment(document.date.toDate()).format("DD/MM/yyyy")}\n`;
      historyObject.text += `Ποσό: €${document.amount}\n`;

      if (document.idate != null) historyObject.text += `Έκδοση Παραστατικού: #${document.inum} - ${moment(document.idate.toDate()).format("DD/MM/yyyy")} - €${document.iamount ?? 0}\n`;

      if (document.notes != null && document.notes.trim() != "") historyObject.text += `Παρατηρήσεις: ${document.notes}\n`;
    }

    const historyRef = doc(collection(db, window.dbRoot + "History"));
    batch.set(historyRef, historyObject);

    if (newBatch) {
      console.log("commiting batch");
      await batch.commit();
    }

    if (dbCollection === window.dbRoot + "payments" || dbCollection === window.dbRoot + "subscriptions") {
      await updateClientSums(document.client, false);
    }

    return docRef.id;
  } catch (error) {
    console.log(error);
    window.showAlert(error.toString());
    return -1;
  }

  // var docToDelete = { ...document }
  // delete docToDelete.docRef;

  // const newBatch = (batch === undefined || batch === null);
  // if (newBatch) batch = writeBatch(db);

  // const docRef = doc(db, dbCollection, uid);

  // console.log(docToDelete,docRef, uid, dbCollection);

  // batch.delete(docRef);
  // const logRef = doc(collection(db, window.dbRoot + "logs"));

  // batch.set(logRef, { type: 3, timestamp: serverTimestamp(), ref: docRef, user: user.displayName, doc: JSON.stringify(docToDelete) })
  // if (newBatch) await batch.commit();

  // if (dbCollection === window.dbRoot + 'payments' || dbCollection === window.dbRoot + 'subscriptions') {
  //   await updateClientSums(document.client, false)
  // }
  // return docRef.id
};

export const updateDayPresences = async (list, date) => {
  // const dbCollection = "subscriptions";
  // const dbPresences = window.appState.subscriptions.filter(p => moment(p.date).format("YYYY/MM/DD") === moment(date).format("YYYY/MM/DD")).map(p => p.client.path.split('/').slice(-1)[0])
  // //console.log(dbPresences)
  // //console.log(list)
  // const newP = list.filter(x => !dbPresences.includes(x))
  // const delP = dbPresences.filter(x => !list.includes(x))
  // const existP = dbPresences.filter(x => list.includes(x))
  // let msg1 = "";
  // let c1 = 0;
  // window.appState.clients.filter(c => newP.includes(c.id)).forEach(c => {
  //   msg1 = msg1 + getClientExtraData(c).fullName + '<br />'
  //   c1++;
  // });
  // let msg2 = "";
  // let c2 = 0;
  // window.appState.subscriptions.filter(p => moment(p.date).format("YYYY/MM/DD") === moment(date).format("YYYY/MM/DD") && delP.includes(p.client.path.split('/').slice(-1)[0])).
  //   forEach(doc => {
  //     msg2 = msg2 + getClientExtraData(window.appState.clients.filter(c => c.id === doc.client.path.split('/').slice(-1)[0])[0]).fullName + '<br />'
  //     c2++;
  //   });
  // const msg = (msg1 ? `<b>Πρόσθεση παρουσίας σε: (${c1})</b><br/>` + msg1 + '<br/>' : '') + (msg2 ? `<b>Αφαίρεση παρουσίας από: (${c2})</b><br/>` + msg2 + '<br/>' : '')
  // if (c1 + c2 === 0) {
  //   let c3List = [];
  //   window.appState.subscriptions.filter(p => moment(p.date).format("YYYY/MM/DD") === moment(date).format("YYYY/MM/DD") && existP.includes(p.client.path.split('/').slice(-1)[0])).
  //     forEach(doc => {
  //       c3List.push(window.appState.clients.filter(c => c.id === doc.client.path.split('/').slice(-1)[0])[0])
  //     });
  //   const amsg = `Δεν υπάρχουν αλλαγές στις παρουσίες της ημέρας (${c3List.length})<br/><br/>` + c3List.map(c => getClientExtraData(c).fullName).sort((a, b) => a.localeCompare(b)).join('<br/>')
  //   window.showAlert(<div dangerouslySetInnerHTML={{ __html: amsg }} />, 'Ενημέρωση', 'Ενταξει', () => checkForProblems(c3List))
  // }
  // if (c1 + c2 > 0) {
  //   window.showDialog(
  //     {
  //       Message: <div dangerouslySetInnerHTML={{ __html: msg }} ></div>,
  //       Title: 'Επιβεβαίωση',
  //       onAgree: (async () => {
  //         const batch = writeBatch(db);
  //         window.appState.clients.filter(c => newP.includes(c.id)).forEach(c => {
  //           const doc = { client: c.docRef, date, user: window.appState.user.displayName }
  //           saveDoc(doc.id, 'subscriptions', doc, window.appState.user, batch)
  //         });
  //         window.appState.subscriptions.filter(p => moment(p.date).format("YYYY/MM/DD") === moment(date).format("YYYY/MM/DD") && delP.includes(p.client.path.split('/').slice(-1)[0])).
  //           forEach(doc => {
  //             delDoc(doc.id, 'subscriptions', doc, window.appState.user, batch)
  //           });
  //         await batch.commit();
  //       })
  //     }
  //   )
  // }
};

//Clients

String.prototype.contains = function (sub) {
  if (sub === "") return true;
  if (!sub || !this.length) return false;
  sub = "" + sub;
  if (sub.length > this.length) return false;
  let ascii = (s) =>
    s
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  return ascii(this).includes(ascii(sub));
};

export const getSortedClientList = (list, hideIncative = false, filter = "") => {
  let filterList;
  filterList = list?.filter((i) => (i.status === 1 || i.status === 2 || !hideIncative) && (filter === null || filter.trim() === "" || i.lname?.contains(filter) || i.fname?.contains(filter)));

  if (window.fnameFirst) {
    return filterList?.sort((a, b) => ((a.fname ? a.fname : "") + a.lname).localeCompare((b.fname ? b.fname : "") + b.lname));
  } else {
    return filterList?.sort((a, b) => (a.lname + (a.fname ? a.fname : "")).localeCompare(b.lname + (b.fname ? b.fnameb : "")));
  }
};

export const getClientFullName = (client) => {
  //return client;
  try {
    if (!client.lname) client = window.appState.clients.filter((c) => c.id === client)[0];

    return window.fnameFirst ? `${client.fname ? client.fname : ""} ${client.lname ? client.lname : ""}` : `${client.lname ? client.lname : ""} ${client.fname ? client.fname : ""}`;
  } catch (error) {
    console.log(error);
    return client;
  }
};

export const getClientExtraData = (client, html) => {
  let fullName = window.fnameFirst ? `${client.fname ? client.fname : ""} ${client.lname ? client.lname : ""}` : `${client.lname ? client.lname : ""} ${client.fname ? client.fname : ""}`;
  if (fullName.trim() === "") fullName = `${client.id}`;
  let avatar = `${client.lname ? client.lname.toString().substring(0, 1).toUpperCase() : ""}${client.fname ? client.fname.toString().substring(0, 1).toUpperCase() : ""}`;

  let tmpNum = client.totalPresences % client.workouts;
  if (tmpNum === 0 && client.totalPresences > 0) tmpNum = client.workouts;
  let num = `${zfill(tmpNum, 2)}`;

  //let fullNameWithNums = num + ' ' + fullName + (getClientDebt(client) >= client.amount ? ` €${roundNumber(client.totalWorkoutsAmount - client.totalPayments, 0) - (client.discount ? client.discount : 0)}` : '');
  let fullNameWithNums;

  if (html) {
    fullNameWithNums = (
      <>
        {num}&nbsp;&nbsp;{fullName}&nbsp;&nbsp;<span style={{ color: "red", fontWeight: "600" }}>{getClientXDebt(client, true)}</span>
      </>
    );
  } else {
    fullNameWithNums = num + " " + fullName + " " + getClientXDebt(client, true);
  }

  return { fullName, avatar, fullNameWithNums, num };
};
export const getClientDebt = (client, format, digits, showZero) => {
  let out = "€" + client.debt.toLocaleString("el-GR");
  //if (format) out = (out > 0.99 || out < 0) ? `€${roundNumber(out, digits !== undefined ? digits : 2)}` : (showZero === true ? `€${roundNumber(0, digits !== undefined ? digits : 2, showZero)}` : '');
  return out;
};

export const clientHasInvoice = (client) => {
  if (client.status == 1 || client.status == 2) {
    //get the first active subscription

    let months;
    let subscription = getClientSubscriptions(client)[0];
    if (subscription) {
      months = window.appState.plans.filter((p) => p.id === subscription.plan)[0]?.months;
      let nextCharge = getSubscriptionNextCharge(subscription);

      if (nextCharge) {
        let startDate = new Date(nextCharge.getFullYear(), nextCharge.getMonth() - months, nextCharge.getDate());

        //get client count of payments that have idate >= startDate
        let count = window.appState.payments?.filter((p) => p.client === client.id && p.idate && p.idate.toDate().getTime() >= startDate.getTime()).length;

        if (count > 0) return true;
        else return false;
      }
    }

    //let subscription = window.appState.subscriptions.filter((s) => s.client.path.split("/").slice(-1)[0] === client.id )[0];

    // if (subscription) {
    //   let plan = window.appState.plans.filter((p) => p.id === subscription.plan.path.split("/").slice(-1)[0])[0];
    //   if (plan) {
    //     let nextCharge = subscription.nextCharge(plan);
    //     let startDate = new Date(nextCharge.getFullYear(), nextCharge.getMonth() - plan.months, nextCharge.getDate());
    //     let count = window.appState.payments.filter((p) => p.client.path.split("/").slice(-1)[0] === client.id && p.idate && p.idate.toDate().getTime() >= startDate.getTime()).length;
    //     console.log(count);
    //   }
    // }

    //   DocumentSnapshot snapshot = appData.subscriptions
    //       .firstWhere((doc) => doc.data['client'] == reference.documentID);

    //   Subscription resentSubscription = Subscription.fromSnapshot(snapshot);
    //   Plan plan = Plan.fromDocumetId(resentSubscription.plan);
    //   int planMonths = plan.months;
    //   DateTime nextCharge = resentSubscription.nextCharge(plan);
    //   DateTime startDate = new DateTime(
    //       nextCharge.year, nextCharge.month - planMonths, nextCharge.day);
    //   int count = appData.payments
    //       .where((doc) =>
    //           doc.data['client'] == reference.documentID &&
    //           doc.data['idate'] != null &&
    //           doc.data['idate'].toDate().isAfter(startDate))
    //       .length;

    //   if (count > 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else
    //   return false;

    return true;
  } else {
    return false;
  }
};

export const getClientXDebt = (client, format) => {
  //let out = (client.totalWorkoutsAmount ? client.totalWorkoutsAmount : 0) - (client.totalPayments ? client.totalPayments : 0) - (client.discount ? client.discount : 0)
  let out =
    (client.totalWorkoutsAmount ? client.totalWorkoutsAmount : 0) -
    (((client.totalPresences ? client.totalPresences : 0) % (client.workouts ? client.workouts : 12)) * (client.amount ? client.amount : 80)) / (client.workouts ? client.workouts : 12);
  out = out - (client.totalPayments ? client.totalPayments : 0) - (client.discount ? client.discount : 0);
  out = out > 0.99 ? roundNumber(out, 2) : 0;
  if (format) out = out !== 0 ? `€${out}` : "";
  return out;
};
export const getClientSubscriptions = (client) => {
  return window.appState.subscriptions ? window.appState.subscriptions.filter((s) => s.client === client.id).sort((a, b) => Number(b.from.toDate()) - Number(a.from.toDate())) : [];
};

export const getClientStatus = (client) => {
  let text = "";
  let color = "#999";
  const status = client.status;
  if (status == 0) {
    text = "Δεν υπάρχουν συνδρομές";
  } else if (status == 1) {
    text = "Υπάρχει συνδρομή που θα ανανεωθεί αυτόματα";
    color = "blue";
  } else if (status == 2) {
    text = "Υπάρχει συνδρομή που θα λήξει";
    color = "yellow";
  } else if (status == 3) {
    text = "Υπάρχει συνδρομή που έχει λήξει";
    color = "red";
  }
  return { text, color };
};

//updateClientData -> updateSubscriptionsSum, updatePaymentsSum, updateDebt, updateStatus

//updateClientsData -> forEacth client updateClientData

//Subscription
export const getSubscriptionNextCharge = (subscription) => {
  let from = subscription.from.toDate();

  if (subscription.extraDays && subscription.extraDays !== 0) {
    from.setDate(from.getDate() + subscription.extraDays);
  }

  //check if subscription to date is before today

  if (subscription.to && subscription.to.toDate().getTime() < new Date().getTime()) {
    return null;
  } else {
    const plan = window.appState.plans.filter((p) => p.id === subscription.plan)[0];

    let to = new Date();
    to.setHours(0, 0, 0, 0);

    let months = to.getFullYear() * 12 + to.getMonth() - (from.getFullYear() * 12 + from.getMonth());
    if (to.getDate() >= from.getDate()) months++;
    let payPeriods = Math.floor(months / plan.months);

    if (payPeriods * plan.months < months) payPeriods++;

    return new Date(from.getFullYear(), from.getMonth() + payPeriods * plan.months, from.getDate());
  }
};
export const getSubscriptionCost = (subscription) => {
  const plan = window.appState.plans.filter((p) => p.id === subscription.plan)[0];

  let from = subscription.from.toDate();

  if (subscription.extraDays != NaN && (subscription.extraDays ?? 0 !== 0)) {
    from.setDate(from.getDate() + subscription.extraDays);
  }

  let to = subscription.to?.toDate() ?? new Date();

  let months = to.getFullYear() * 12 + to.getMonth() - (from.getFullYear() * 12 + from.getMonth());
  if (to.getDate() >= from.getDate()) months++;
  let payPeriods = Math.floor(months / plan.months);

  if (payPeriods * plan.months < months) payPeriods++;

  if (payPeriods == 0 && subscription.from.toDate() < new Date()) payPeriods = 1;

  let price = subscription.price ?? plan.price;

  let cost = payPeriods * price;

  if (subscription.reduction != NaN) cost = cost - subscription.reduction ?? 0;

  console.log("getSubscriptionCost", subscription, cost);

  return cost ?? 0;
};

//Plan
export const getPlanColor = (plan) => {
  if (plan.inActive) return "#777";
  else return "#aa0";
};
export const getSortedPlansList = (list, hideIncative = false, filter = "") => {
  let filterList;
  filterList = list?.filter((i) => (i.inActive !== true || !hideIncative) && (filter === null || filter.trim() === "" || i.name?.contains(filter)));
  {
    return filterList?.sort((a, b) => a.name.localeCompare(b.name));
  }
};

export const getPlanFullName = (plan, full) => {
  return `${plan.sessions > 0 ? plan.sessions : ""} ${plan.name}${full ? " - " + getPlanExtra(plan) : ""}`.trim();
};

export const getPlanExtra = (plan) => {
  return `${plan.price > 0 ? `${plan.price}€ / ` : ""} ${plan.months > 0 ? `${plan.months} μήνες` : plan.months}`.trim();
};

export const getSubscriptionPlanData = (subscription) => {
  if (subscription) {
    const plan = window.appState.plans.filter((p) => p.id === subscription.plan)[0];

    const displaName = getPlanFullName(plan) + ` €${subscription.price}/${plan.months > 1 ? `${plan.months} μήνες` : "μήνα"} `;
    const displayPriceReduction = subscription.reduction > 0 ? `Εφάπαξ έκπτωση: €${subscription.reduction}` : "";
    const displayPlanReduction = subscription.price !== plan.price ? `€${subscription.price} από €${plan.price} - Έκπτωση ${Math.round(100 - (100 * subscription.price) / plan.price)}%` : "";
    const displayCost = subscription.cost > 0 ? `Κόστος συνδομής${subscription.to ? "" : " ως σήμερα"} €${subscription.cost.toLocaleString("el-GR")}` : "";
    //+      ` (€${getSubscriptionCost(subscription).toLocaleString("el-GR")})`;
    const nextCharge = getSubscriptionNextCharge(subscription);
    const displaynextCharge = "Ανανέωση: " + moment(nextCharge).format("DD/MM/yyyy");
    return { plan, displaName, displayPriceReduction, displayPlanReduction, displayCost, nextCharge, displaynextCharge };
  } else return {};
};
export const getSubscriptionPlanDisplayName = (subscription) => {
  const p = window.appState.plans.filter((p) => p.id === subscription.plan)[0];
  return getPlanFullName(p) + ` - €${subscription.price}/${p.months > 1 ? `${p.months} μήνες` : "μήνα"} `;
};

////////////////////////////////////////

export const updateClientSums = async (client, isClient, checkClient = false) => {
  if (isClient) {
    //   const amount = client.amount ? client.amount : 80
    //   const workouts = client.workouts ? client.workouts : 12
    //   let totalPayments = 0
    //   let totalPresences = 0
    //   let totalWorkoutsAmount = 0
    //   const clientRef = client.docRef
    //   if (clientRef) {
    //     const presencesQuery = query(collection(db, window.appState.dbRoot + "subscriptions"), where("client", "==", clientRef));
    //     const presencesSnapshot = await getDocs(presencesQuery);
    //     totalPresences = presencesSnapshot.docs.length;
    //     totalWorkoutsAmount = roundNumber(totalPresences * amount / workouts, 2);
    //     const paymentsQuery = query(collection(db, window.appState.dbRoot + "payments"), where("client", "==", clientRef));
    //     const paymentsSnapshot = await getDocs(paymentsQuery);
    //     if (paymentsSnapshot.docs.length > 0) {
    //       totalPayments = paymentsSnapshot.docs.map(i => i.data().amount).reduce((prev, curr) => parseInt(prev) + parseInt(curr))
    //     }
    //   }
    //   return { ...client, amount, workouts, totalPresences, totalWorkoutsAmount, totalPayments }
  } else {
    let c = client;

    if (typeof c === "string") {
      c = window.appState.clients.filter((c) => c.id === client)[0];
    }

    let paymentsSum = window.appState.payments.filter((p) => p.client === c.id).reduce((prev, curr) => (parseInt(prev) ?? 0) + (parseInt(curr.amount) ?? 0), 0);
    let subscriptionsSum = window.appState.subscriptions.filter((s) => s.client === c.id).reduce((prev, curr) => (parseInt(prev) ?? 0) + (parseInt(curr.cost) ?? 0), 0);
    let debt = (subscriptionsSum ?? 0) - (paymentsSum ?? 0);

    //   if (!checkClient)
    //     client = window.appState.clients.filter(c => c.id === client.id)[0]
    //   const amount = client.amount ? client.amount : 80
    //   const workouts = client.workouts ? client.workouts : 12
    //   const clientPath = client.docRef.path
    //   const clientRef = client.docRef
    //   const presencesQuery = query(collection(db, window.appState.dbRoot + "subscriptions"), where("client", "==", clientRef));
    //   const presencesSnapshot = await getDocs(presencesQuery);
    //   const totalPresences = presencesSnapshot.docs.length;
    //   const totalWorkoutsAmount = roundNumber(totalPresences * amount / workouts, 2);
    //   let totalPayments = 0
    //   const paymentsQuery = query(collection(db, window.appState.dbRoot + "payments"), where("client", "==", clientRef));
    //   const paymentsSnapshot = await getDocs(paymentsQuery);
    //   if (paymentsSnapshot.docs.length > 0) {
    //     totalPayments = paymentsSnapshot.docs.map(i => i.data().amount).reduce((prev, curr) => parseInt(prev) + parseInt(curr))
    //   }
    if (checkClient && c.subscriptionsSum === subscriptionsSum && c.debt === debt && c.paymentsSum === paymentsSum) {
      return;
    } else if (checkClient) {
      console.log(`Διόρθωση Προβλήματος στον πελάτη:${c.lname}`);
      console.log(c.subscriptionsSum, subscriptionsSum, c.debt, debt, c.paymentsSum, paymentsSum);
    }
    updateDoc(c.docRef, { paymentsSum, subscriptionsSum, debt });
  }
};

////////////////////////////////////////
export const checkForProblems = (clientList) => {
  if (!clientList) clientList = window.appState.clients.filter((c) => c.status !== 3);
  clientList.forEach((clinet) => {
    updateClientSums(clinet, false, true);
  });
};

export function roundNumber(num, scale, showZero) {
  let toReturn = 0;

  if (!("" + num).includes("e")) {
    toReturn = +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }

    toReturn = +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
  if (!showZero && toReturn === 0) {
    return "";
  } else {
    return toReturn;
  }
}
export function zfill(num, len) {
  return (Array(len).join("0") + num).slice(-len);
}

export const appInitLog = async (currentToken) => {
  const logRef = doc(collection(db, window.dbRoot + "appLogs"));
  await setDoc(logRef, {
    timestamp: serverTimestamp(),
    currentToken,
    user: window.appState.UserData.userDisplayName,
    ver: window.version,
    device: `${mobileModel.replace("none", "Desktop")} ${browserName} ${browserVersion} ${osName} ${osVersion}`,
  });
};
export const updateUserSetting = async (setting) => {
  // const q = query(collection(db, "users"), where("uid", "==", window.appState.user?.uid));
  // const doc = await getDocs(q);
  // if (doc.docs[0].exists) {
  //   const docRef = doc.docs[0].ref;
  //   await updateDoc(docRef, setting);
  // }
};

////////////////

export const dateDiffString = (date) => {
  let date2 = new Date();

  let Difference_In_Time = date2.getTime() - date.getTime();

  let days = Difference_In_Time / (1000 * 3600 * 24);

  let out = "";

  days = Math.floor(days);

  if (days === 0) {
    return "Σήμερα";
  } else if (days === 1) {
    return "Χθες";
  } else if (days === -1) {
    return "Aύριο";
  } else if (days < 0) {
    out += "Σε ";
    days = Math.abs(days);
  } else if (days > 0) {
    out += "Πριν ";
  }

  if (days > 7 && days < 32) {
    let weeks = Math.floor(days / 7);
    out += weeks == 1 ? "μία εβδομάδα " : weeks.toString() + " εβδομάδες";
  } else if (days >= 32 && days < 365) {
    let months = Math.floor(days / 30);
    out += months == 1 ? "ένα μήνα" : months.toString() + " μήνες";
  } else if (days >= 365) {
    let years = Math.round(days / 365);
    out += years == 1 ? "ένα χρόνο" : years.toString() + " χρόνια";
  } else {
    out = days == 1 ? "Χθές" : days == 0 ? "Σήμερα" : out + Math.floor(days).toString() + " μέρες";
  }

  return out;
};
