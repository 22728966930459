import "./forms.css";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Paper } from '@mui/material';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { AppContext } from "../../App";
import { fetchDoc, saveDoc, delDoc, getClientExtraData } from '../../data/db'
import { TextInput, MobileDateInput, FormRow, CheckBox } from "../controls/Inputs"


import { SavedSearchOutlined, UnfoldLess } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Client(props) {

    const appState = useContext(AppContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const [doc, setDoc] = useState(null)

    useEffect(() => {
        if (appState.dbRoot === undefined) navigate('/dashboard/1')
    }, [])


    useEffect(() => {
        if (id && appState.dbRoot !== undefined) fetchDoc(id, 'clients').then(setDoc)
    }, [id])


    return (

        <Box style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",

        }}>
            <Box style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", backgroundColor: "#ffffea", overflow: "auto" }}
            >
                <div style={{ flex: "1", minHeight: "60px" }} >
                    <AppBar position="fixed">
                        <Toolbar style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                            <IconButton
                                color="inherit"
                                onClick={() => { navigate(-1) }}
                                edge="start">
                                <BackIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div" style={{ flex: 50, userSelect: "none" }} >
                                {doc ? getClientExtraData(doc).fullName : 'Νεος πελάτης'}

                            </Typography>
                            {/*temporarily disabled save*/}
                            {/* {id !== undefined &&
                                <IconButton
                                    color="inherit"
                                    onClick={() => {
                                        window.setAppState((appState) => (
                                            {
                                                ...appState,
                                                DialogData:
                                                {
                                                    Message: "Να πραγματοποιηθεί η διαγραφή του πελάτη;",
                                                    onAgree: () => {

                                                        delDoc(id, 'clients', doc, appState.user)
                                                            .then(() => navigate(-1))
                                                            .catch((error) => {
                                                                window.showAlert(`${error}`)
                                                            });
                                                    }
                                                }
                                            }))

                                    }}
                                    style={{ paddingInline: "7px" }}

                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    if (!doc || doc.lname === undefined) {
                                        window.showAlert('Το επώνυμο είναι υποχραιωτικό!')
                                    }
                                    else {
                                        window.showDialog(
                                            {
                                                Message: "Να πραγματοποιηθεί η αποθήκευση του πελάτη;",
                                                ButtonExtraName: "Ναι & Επιστροφη",
                                                onAgree: () => {
                                                    saveDoc(id, 'clients', doc, appState.user).then((refId) => {
                                                        if (!id) navigate(`/client/${refId.id}`, { replace: true });
                                                    }).catch((error) => {
                                                        window.showAlert(`${error}`)
                                                    })
                                                },
                                                onExtraButton: () => {
                                                    saveDoc(id, 'clients', doc, appState.user).then((refId) => {
                                                        navigate(-1);
                                                    }).catch((error) => {
                                                        window.showAlert(`${error}`)
                                                    })
                                                }
                                            }
                                        )
                                    }
                                }}
                                style={{ paddingInline: "7px" }}
                            >
                                <SaveIcon />
                            </IconButton> */}
                        </Toolbar>
                    </AppBar>
                </div>
                <Box style={{ padding: "0px 10px 10px 10px", marginTop: "7px", display: 'flex', flexDirection: "column", height: "100%", overflow: "auto" }}>

                    {/* <div style={{ minWidth: "100", display: 'flex', flexDirection: "row", justifyContent: "flex-end" }}>
                        <CheckBox field="inActive" label="Ανενεργός" object={doc} setObject={setDoc} />
                    </div> */}

                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="4" field="lname" label="Επώνυμο" object={doc} setObject={setDoc} spellCheck={false} />
                        <TextInput flex="4" field="fname" label="Όνομα" object={doc} setObject={setDoc} />
                    </FormRow>

                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="1" field="email" label="email" object={doc} setObject={setDoc} type="email" />
                    </FormRow>

                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="4" field="mobile" label="Κινήτο τηλέφωνο" object={doc} setObject={setDoc} type="number" />
                        <TextInput flex="4" field="phone" label="Σταθεό τηλέφωνο" object={doc} setObject={setDoc} type="number" />
                    </FormRow>

                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput type="date" flex="5" field="birthdate" label="Ημερ. Γέννησης" object={doc} setObject={setDoc} />
                        <TextInput flex="3" field="height" label="Ύψος" object={doc} setObject={setDoc} type="cm" />
                        <TextInput flex="3" field="Weight" label="Βάρος" object={doc} setObject={setDoc} type="kg" />
                    </FormRow>

                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="3" field="addres" label="Διεύθυνση κατοικίας" object={doc} setObject={setDoc} />
                        <TextInput flex="1" field="addresNo" label="Αριθμός" object={doc} setObject={setDoc} />
                    </FormRow>

                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="3" field="city" label="Πόλη" object={doc} setObject={setDoc} />
                        <TextInput flex="3" field="district" label="Περιοχή" object={doc} setObject={setDoc} />
                        <TextInput flex="2" field="tk" label="ΤΚ" object={doc} setObject={setDoc} type="number" />
                    </FormRow>


                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="1" field="medicalIssues" label="Ιατρικά Θέματα" object={doc} setObject={setDoc} rows={3} />
                    </FormRow>


                    <FormRow style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <TextInput flex="1" field="notes" label="Παρατηρήσεις" object={doc} setObject={setDoc} rows={3} />
                    </FormRow>


                </Box>
            </Box >
        </Box >
    )

}