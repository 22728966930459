import "./App.css";
import React, { useEffect, useState, createContext, useReducer } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import Login from "./forms/loginForms/Login";
import Register from "./forms/loginForms/Register";
import Reset from "./forms/loginForms/Reset";
import Dashboard from "./forms/Dashboard";
import Client from "./forms/subForms/Client";
import Plan from "./forms/subForms/Plan";
import Subscription from "./forms/subForms/Subscription";
import ClientInfo from "./forms/subForms/ClientInfo";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "@mui/material/Zoom";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "./data/firebase";
import { appInitLog, checkForProblems, nameReplace } from "./data/db";
import { query, collection, onSnapshot } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

let deferredPrompt;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
  //return <Slide direction="down" ref={ref} {...props} />;
});

let dbRoot = "/Debug/001/";
window.dbRoot = dbRoot;

export const AppContext = createContext(null);

function App() {
  const [user, loading, error] = useAuthState(auth);

  const [installable, setInstallable] = useState(false);
  window.installable = installable;
  const [appState, setAppState] = useState({ nameReplace });

  window.appState = appState;
  window.setAppState = setAppState;

  window.showDialog = (DialogData) => {
    window.setAppState((prevState) => ({ ...prevState, DialogData }));
  };

  window.showAlert = (message, title, buttonName, onAgree) => {
    window.setAppState((prevState) => ({
      ...prevState,
      AlertData: {
        Title: title ? title : "Πρόβλημα",
        Message: message ? message : "...",
        ButtonAgreeName: buttonName,
        onAgree,
      },
    }));
  };

  window.showModal = (ModalData) => {
    window.setAppState((prevState) => ({ ...prevState, ModalData }));
  };

  useEffect(() => {
    if (appState.user !== user) {
    }
    setAppState((prevState) => ({ ...prevState, user }));
  }, [user]);

  useEffect(async () => {
    if (appState && appState.dbRoot != undefined) {
      const messaging = getMessaging();

      let currentToken = "";
      await appInitLog(currentToken);

      if (!appState.clients) {
        const q = query(collection(db, appState.dbRoot + "Clients"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          setAppState((prevState) => {
            return {
              ...prevState,
              clients: querySnapshot.docs.map((d) => {
                return { ...d.data(), id: d.id, docRef: d.ref };
              }),
            };
          });
        });
      }
      if (!appState.plans) {
        const q = query(collection(db, appState.dbRoot + "Plans"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          setAppState((prevState) => {
            return {
              ...prevState,
              plans: querySnapshot.docs.map((d) => {
                return { ...d.data(), id: d.id, docRef: d.ref };
              }),
            };
          });
        });
      }
      if (!appState.subscriptions) {
        const q = query(collection(db, appState.dbRoot + "Subscriptions"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          setAppState((prevState) => {
            return {
              ...prevState,
              subscriptions: querySnapshot.docs.map((d) => {
                return { ...d.data(), id: d.id, docRef: d.ref };
              }),
            };
          });
        });
      }
      if (!appState.payments) {
        const q = query(collection(db, appState.dbRoot + "Payments"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          setAppState((prevState) => {
            return {
              ...prevState,
              payments: querySnapshot.docs.map((d) => {
                return { ...d.data(), id: d.id, docRef: d.ref };
              }),
            };
          });
        });
      }
    }
  }, [appState.dbRoot]);

  useEffect(() => {
    if (
      appState &&
      appState.initOk !== true &&
      appState.payments &&
      appState.subscriptions &&
      appState.clients &&
      appState.plans &&
      appState.payments.length > 0 &&
      appState.subscriptions.length > 0 &&
      appState.clients.length > 0 &&
      appState.plans.length > 0
    ) {
      setAppState((prevState) => ({ ...prevState, initOk: true }));

      checkForProblems();

      console.log("initOk", appState.payments.length, appState.subscriptions.length, appState.clients.length, appState.plans.length);
    }
  }, [appState.payments, appState.subscriptions, appState.clients, appState.plans]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
  }, []);

  const handleInstallClick = (e) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  };

  window.handleInstallClick = handleInstallClick;

  return (
    <div>
      <AppContext.Provider value={appState}>
        <div className="app" style={{ position: "relative" }}>
          {installable && (
            <div style={{ position: "absolute", top: 10, right: 10 }}>
              <button style={{ padding: "6px", backgroundColor: "#ddf" }} onClick={handleInstallClick}>
                Εγκατάσταση Εφαρμογής
              </button>
            </div>
          )}
          <HashRouter>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/client/:id" element={<Client />} />
              <Route exact path="/client" element={<Client />} />
              <Route exact path="/plan/:id" element={<Plan />} />
              <Route exact path="/subscription" element={<Subscription />} />
              <Route exact path="/subscription/:id" element={<Subscription />} />
              <Route exact path="/plan" element={<Plan />} />

              <Route exact path="/clientInfo" element={<ClientInfo />} />
              <Route exact path="/clientInfo/:id" element={<ClientInfo />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/reset" element={<Reset />} />
              <Route exact path="/dashboard/:menuId" element={<Dashboard />} />
            </Routes>
          </HashRouter>
        </div>

        <Dialog open={appState.ModalData !== undefined} TransitionComponent={Transition} keepMounted>
          <DialogContent>
            {appState.ModalData && appState.ModalData.Title && <DialogTitle style={{ fontSize: "min(4.5vw,16px)", padding: "7px" }}>{appState.ModalData.Title}</DialogTitle>}
            <DialogContentText id="app-modal">{appState.ModalData ? appState.ModalData.Body : ""}</DialogContentText>
          </DialogContent>
          {appState.ModalData && appState.ModalData.Type === "okOnly" && (
            <DialogActions>
              <Button
                variant="text"
                onClick={() => {
                  setAppState({ ...appState, ModalData: undefined });
                }}
              >
                {appState.ModalData && appState.ModalData.ButtonOKName ? appState.DialogData.ButtonOKName : "OK"}
              </Button>
            </DialogActions>
          )}
        </Dialog>

        <Dialog
          open={appState.DialogData !== undefined}
          TransitionComponent={Transition}
          keepMounted
          // onClose={appState.DialogData ? appState.DialogData.onClose : undefined}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{appState.DialogData && appState.DialogData.Title ? appState.DialogData.Title : "Ερώτηση"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{appState.DialogData ? appState.DialogData.Message : ""}</DialogContentText>
          </DialogContent>
          {appState.DialogData && appState.DialogData.Type !== "noButtons" && (
            <DialogActions>
              {appState.DialogData && appState.DialogData.Type !== "okOnly" && (
                <Button
                  variant="text"
                  onClick={() => {
                    setAppState({ ...appState, DialogData: undefined });
                    if (appState.DialogData.onDisagree) appState.DialogData.onDisagree();
                  }}
                >
                  {appState.DialogData && appState.DialogData.ButtonDisagreeName ? appState.DialogData.ButtonDisagreeName : "Οχι"}
                </Button>
              )}

              <Button
                variant="text"
                onClick={() => {
                  setAppState({ ...appState, DialogData: undefined });
                  if (appState.DialogData.onAgree) appState.DialogData.onAgree();
                }}
              >
                {appState.DialogData && appState.DialogData.ButtonAgreeName ? appState.DialogData.ButtonAgreeName : appState.DialogData && appState.DialogData.Type === "okOnly" ? "Ενταξει" : "Ναι"}
              </Button>

              {appState.DialogData && appState.DialogData.ButtonExtraName && (
                <Button
                  variant="text"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    setAppState({ ...appState, DialogData: undefined });
                    if (appState.DialogData.onExtraButton) appState.DialogData.onExtraButton();
                  }}
                >
                  {appState.DialogData && appState.DialogData.ButtonExtraName ? appState.DialogData.ButtonExtraName : ""}
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>

        <Dialog open={appState.AlertData !== undefined} TransitionComponent={Transition} keepMounted>
          <DialogTitle>{appState.AlertData && appState.AlertData.Title ? appState.AlertData.Title : "Πρόβλημα"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{appState.AlertData ? appState.AlertData.Message : ""}</DialogContentText>
          </DialogContent>
          {appState.AlertData && appState.AlertData.Type !== "noButtons" && (
            <DialogActions>
              <Button
                variant="text"
                onClick={() => {
                  try {
                    if (appState.AlertData.onAgree) appState.AlertData.onAgree();
                    setAppState({ ...appState, AlertData: undefined });
                  } catch (error) {
                    window.showAlert(`${error}`);
                  }
                }}
              >
                {appState.AlertData && appState.AlertData.ButtonAgreeName ? appState.AlertData.ButtonAgreeName : "Ενταξει"}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </AppContext.Provider>
    </div>
  );
}

export default App;
